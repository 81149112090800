import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { refreshToken } from './refresh.js'

import { withStyles } from '@mui/styles';
import {
  AppBar,
  Autocomplete,
  Button,
  Card, 
  CardContent, 
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Menu,
  MenuItem,
  Slide,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import HealingIcon from '@mui/icons-material/Healing'
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import InventoryIcon from '@mui/icons-material/Inventory'
import ShowChartIcon from '@mui/icons-material/ShowChart'


import axios from 'axios'
import clsx from 'clsx';

import { grey, red } from '@mui/material/colors'

import Compressor from 'compressorjs';


const DATEOFVISIT = new Date().toJSON().slice(0, 10)
// const DATEOFVISIT = new Date().toJSON().slice(0,10).replace(/-/g,'/');

const STOMA = '2'

const styles = (theme) => ({
  addButton: {
    bottom: 0,
    right: 0,
  },
  card: {
    // Wound Solutions
    // backgroundColor: '#ffe4cc',
    background: 'linear-gradient(90deg, #ffe4cc 90%, #ffe4cc 90%)',
    // background: 'linear-gradient(90deg, #ff91a3 90%, #ff91a3 90%)',
    marginLeft: 13,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: 14,
    width: '93%',

  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    background: theme.palette.grey[500],
  },
  content: {
    color: theme.palette.grey[200],
    ["@media (max-width:700px)"]: {
      maxWidth: '414px'
    },
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  deleteButton: {
    //		position: 'fixed',
    bottom: 0,
    left: 0,
  },
  dialogStyle: {
    ["@media (max-width:700px)"]: {
      maxWidth: '414px'
    },
  },
  error: {
    fontSize: '20px',
    color: red[400],
  },
  fileUpload: {
    background: 'transparent',
    border: '1px solid #bbb',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'inline-block:',
    padding: theme.spacing(2),
    width: '220px',
  },
  form: {
    width: '93%',
    marginLeft: 13,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: 14,
  },
  formSeparator1: {
    marginTop: '18pt',
    marginBottom: '6pt',
    fontSize: '16px',
  },
  formSeparator2: {
    marginTop: '24pt',
    marginBottom: '6pt',
    fontSize: '18px',
  },
  imageRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    marginLeft: 20,
    width: 500,
    height: 450,
  },
  marginSeparator: {
    right: theme.spacing(1),
  },
  patient: {
    inputProps: {style: {fontSize: 12}}, 
    InputLabelProps: {style: {fontSize: 10}} 
  },
  pprogress: {
    position: 'absolute',
    left: '10%',
    top: '17%',
  },
  root: {
    minWidth: 375,
    flexGrow: 1,
  },
  submitButton: {
    display: 'block',
    color: 'white',
    textAlign: 'center',
    position: 'absolute',
    right: 10,
  },
  subTitle: {
    fontSize: '24px',
    width: 93,
    color: grey[700],
    top: 20,
    bottom: 20,
    // Wound Solutions:
//    background: 'linear-gradient(45deg, #f4294bD0 30%, #fcc9d1D0 90%)',
    background: 'linear-gradient(45deg, #cb5921D0 30%, #a99787D0 90%)',
    borderRadius: '8px',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: theme.mixins.toolbar,
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '35%',
    top: '30%',
  },
  uploadButton: {
    margin: 0,
    padding: theme.spacing(2),
    height: '56px',
    width: '220px',
  },
  uploadTitle: {
    color: grey[700],
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  viewRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  visitGrid: {
    color: grey[900],
    // Wound Solutions
//    background: 'linear-gradient(45deg, #f4294bD0 30%, #fcc9d1D0 90%)',
    background: 'linear-gradient(45deg, #cb5921D0 30%, #a99787D0 90%)',
  },
  ImageTitle: {
       color: grey[900],
       background: 'linear-gradient(45deg, #FFFFFFD0 30%, #FFFFFFD0 90%)',
  },
  textField: {
    backgroundColor: '#ffefe0', 
    fontSize: '25px'
  }
})

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
))

const clinic0 = {
  id: 0,
  comment: '',
  date: DATEOFVISIT,
  visitId: '',
}

const assessment0 = {
  id: 0,
  allergies: '',
  balance: '',
  ceap: '',
  comorbidities: '',
  depth: '',
  depth2: '',
  depth3: '',
  education: '',
  exudateLevel: '1',
  exudateType: '',
  healingFactors: '',
  healthEducation: '',
  infection: false,
  inflammation: false,
  length: '',
  length2: '',
  length3: '',
  margin: '',
  medInfo: '',
  medications: '',
  mobility: '',
  nutrition: '',
  painLevel: '',
  postsurgical: false,
  site: '',
  smoking: '',
  tbsa: '',
  texas: '',
  treatment: '',
  treatmentPlan: '',
  type: '',
  viability: '',
  visitId: '',
  weight: '',
  width: '',
  width2: '',
  width3: '',
}

let visit0 = {
  id: Math.random(),
  SAID: ' ',
  admin: 0,
  date: DATEOFVISIT,
  firstName: ' ',
  home: '1',
  medicalAid: '',
  memberNo: '',
  notes: '',
  admnotes: '',
  patientName: ' ',
  seenBy: '',
  vac: '1',
}

const PHONEHEIGHT = 470
const PHONEWIDTH = 450
const TABHEIGHT = 480
const TABWIDTH = 960

class visit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      assessment: {},
      buttonText: '',
      clinic: {},
      comment: '',
      createOpen: false,
      dbinfo:'',
      deleteInfo: 0,
      deleteOpen: false,
      deleteVisitOn: false,
      deleteItemOn: false,
      displayAssessment: false,
      displayClinic: false,
      displayStockItems: false,
      displaySubMenuButtons: 0,
      displayVisit: false,
      editOpen: false,
      error: false,
      errors: [],
      fieldname: '',
      fieldvalue: null,
      filter: '',
      filterOff: true,
      filterProducts: [],
      image: null,
      imageLoading: false,
      images: [],
      login: false,
      hMatches: window.matchMedia("(max-height: 500px)").matches,
      wMatches: window.matchMedia("(max-width: 400px)").matches,
      mouseX: null,
      mouseY: null,
      patient: {},
      patients: [],
      productLoading: false,
      products: [],
      selectedRows: null,
      stockItem: {
        id: Math.random(),
        product: '',
        visitId: '',
      },
      stockItems: [],
      title: 'Wound Solutions',
      uiLoading: true,
      vDate: DATEOFVISIT,
      viewOpen: false,
      visit: {
        vac: '1'
      },
      visitId: '',
      visitHistory: null,
      visits: [],
    }

    this.handleDeleteVisit = this.handleDeleteVisit.bind(this)
    this.openVisitEditor = this.openVisitEditor.bind(this)
    this.openStockItemEditor = this.openStockItemEditor.bind(this)
    this.openClinicEditor = this.openClinicEditor.bind(this)
    this.openAssessmentEditor = this.openAssessmentEditor.bind(this)
    this.openAuth = this.openAuth.bind(this)
  }

  fetchVisits = () => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    const visitHistory = this.props.visitHistory
    if (visitHistory !== this.state.visitHistory) {
      const route = visitHistory === true ? `/api/visits/group/${this.props.groupId}` : `/api/visits/gdate/${this.props.groupId}`
      // if historical data is queried then all visits will be loaded, otherwise only of the last 100 days
      // fetch visits collection
      this.setState({ 
        visitHistory,
        uiLoading: true 
      })
      axios
        .get(route)
        .then((response) => {
          this.setState({
            visits: response.data,
            uiLoading: false
          })
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.setState({login: true})
          }
          this.setState({ uiLoading: false })
          console.log(error)
        })
    }
  }
    // fetch products collection
  fetchProducts = () => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    this.setState({ productLoading: true })
    axios
      .get(`/api/products/group/${this.props.groupId}`)
      .then((response) => {
        this.setState({
          products: response.data,
          productLoading: false,
        })
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({login: true})
        }
        else {
          this.setState({ productLoading: false })
          console.log(error)
        }
      })
  }

  componentDidMount = () => {
    const r = refreshToken()
    if (r === 403) {
      this.setState({login:true})
    }
    else {
      this.fetchVisits()
      this.fetchProducts() 
    }
  }

  componentDidUpdate = () => {
      this.fetchVisits()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // This will be triggered if the list of Visits is displayed and you click on the round '+' Button
  handleClickCreateVisit = () => {
    const vDate = DATEOFVISIT
    this.setState({
        buttonText: 'SUBMIT',
        createOpen: true,
        dbinfo:'',
        displaySubMenuButtons:0,
        vDate,
    })

    this.handleChangePatient(null)
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    if (!this.state.patients.length) {
      this.setState({ uiLoading: true })
      axios
        .get(`/api/patients/group/${this.props.groupId}`)
        .then((response) => {
          this.setState({
            patients: response.data,
            uiLoading: false,
          })
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.setState({login: true})
          }
          else {
            this.setState({ uiLoading: false })
            console.log(error)
          }
        })
    }
  }

  openVisitEditor () {

    this.setState({
      buttonText: 'SAVE',
      editOpen: true,
      dbinfo:'',
      displayVisit: true,
      mouseX: null,
      mouseY: null,
    })
  }

  handleCreateVisit = (event) => {

    event.preventDefault()
    const visit = this.state.visit
    visit.date = this.state.vDate
    visit.groupId = this.props.groupId
    let options = {
      url: '/api/visit',
      method: 'post',
      data: visit,
    }

    this.setState({uiLoading:true})
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }

    axios(options)
      .then((response) => {
        const visits = [...this.state.visits]
        const vs = response.data 
        visits.unshift(vs)
        this.setState({
          dbinfo:'Visit successfully created',
          createOpen: false,
          uiLoading: false,
          visits
        })
        this.addDefaultProducts(vs)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({login: true})
        }
        else {
          this.setState({ 
          uiLoading: false, 
          createOpen: true 
          })
          console.log(error)
		    }
      })
  }

  addDefaultProducts(visit) {
    for (const product of this.state.products) {
      if (product.default) {
        this.handleCreateStockItem(product, visit.id)    
      }
    }

  }

  handleUpdateVisit = () => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }

    const {id, ...uvisit} = this.state.visit
    let options = {
        url: `/api/visit/${id}`,
        method: 'put',
        data: uvisit,
    }
    this.setState({ uiLoading: true })
    axios(options)
      .then(() => {
          this.setState({
            displayVisit: false,
            editOpen: false,
            dbinfo:'Visit successfully updated',
            uiLoading: false,
          })
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({login: true})
        }
        else {
          this.setState({
            editOpen: true,
            uiLoading: false,
          })
          console.log(error)
        }
      })
  }

  confirmDeleteVisit = () => {
    this.setState({
      errors: {},
      mouseX: null,
      mouseY: null,
      deleteOpen: true,
    })
  }

  handleDeleteVisit() {
    const visitId = this.state.visitId
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    this.setState({
      deleteOpen: false,
      mouseX:null,
      mouseY:null,
      uiLoading:true
    })
    this.handleDeleteClinic(visitId)
    this.handleDeleteStockItems(visitId)
    this.handleDeleteAssessment(visitId)
    this.deletePhotoBatch(visitId)
    axios
      .delete(`/api/visit/${visitId}`)
      .then(() => {
        const visits = [...this.state.visits.filter((x) => x.id !== visitId)]
        this.setState ({ 
          visits:visits,
          dbinfo:'Visit successfully deleted',
          displaySubMenuButtons:0,
          uiLoading: false,          
        })
        // window.location.reload();
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({login: true})
        }
        else {
          this.setState({ 
            uiLoading: false
          })
          console.log(error)
		    }
      })
  }

  // handleDeleteVisitSubs = () => {
  //   if (this.state.displayStockItems) {
  //     this.handleDeleteStockItems()
  //   } else if (this.state.displayClinic) {
  //     this.handleDeleteClinic()
  //   } else if (this.state.displayAssessment) {
  //     this.handleDeleteAssessment()
  //   } else {
  //     console.log('Big Problem in handleDeleteVisitSubs!')
  //   }
  // }

  // This function will be trigggered if a stock item should be added to the current visit
  handleCreateStockItem = (product, visitId) => {
    if (product === null || product.title === '')
      return
    
    const stockItem = {
      product: product.title,
      quantity: 1,
      visitId
    }
  
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    this.setState({uiLoading:true})
    let options = {
      url: '/api/stockItem',
      method: 'post',
      data: stockItem,
    }
    axios(options)
      .then((response) => {
        const stockItems = [...this.state.stockItems]
        const stockItem = response.data
        for (const y of this.state.products) {
          if (response.data.product === y.title) {
            Object.assign(stockItem, {nappiCode: `${y.nappiCode}`})
            stockItems.push(stockItem)
          }
        }
        this.setState({
              uiLoading:false,
              stockItems
        })
      })
      .catch((error) => {
		if (error.response.status === 403) {
			this.setState({ login: true });
		}
		else {
		  this.setState({ uiLoading: false })
		  console.log(error)
		}
      })
  }

  handleUpdateStockItem(si) {
    const stockItem = {
      visitId: si.visitId,
      product: si.product,
      quantity: +(si.quantity)
    }

//    console.log('handleUpdateStockItem - quantity =',stockItem.quantity)
    let options = {
      url: `/api/stockItem/${si.id}`,
      method: 'put',
      data: stockItem,  
    }
    this.setState({uiLoading:true})
    axios(options)
      .then((response) => {
        const stockItems = []
        for (const x of this.state.stockItems) {
          if (x.id !== si.id) {
            stockItems.push(x)
          }
          else
            stockItems.push(si)
        }
        this.setState({
          uiLoading:false,
          stockItems
        })
      })
      .catch((error) => {
		if (error.response.status === 403) {
			this.setState({ login: true })
		}
		else {
          this.setState({ uiLoading: false })
          console.log(error)
		}
      })
  }

  openStockItemEditor() {

    this.setState({
      buttonText: 'BACK',
      editOpen: true,
      dbinfo:'',
      mouseX: null,
      mouseY: null,
      displayStockItems: true,
    })

    this.setProductFilter()

    if (this.state.stockItems.length === 0) {
      const authToken = localStorage.getItem('AuthToken')
      axios.defaults.headers.common = { Authorization: `${authToken}` }

      const Id = this.state.visit['id']
      this.setState({ uiLoading: true })
      axios
        .get(`/api/stockBatch/${Id}`)
        .then((response) => {
          const stockItems = []
          for (const x of response.data) {
            for (const y of this.state.products) {
              if (x.product === y.title) {
                Object.assign(x, {nappiCode: `${y.nappiCode}`})
                if (x.quantity === undefined) {
                  Object.assign(x, {quantity: 1})
                }
                stockItems.push(x)
                break
              }
            }  
          }
          this.setState({
            uiLoading: false,
            stockItems
          })
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.setState({ login: true })
          }
          else {
            this.setState({ uiLoading: false })
            console.log(error)
          }
        })
    }
  }

  setProductFilter() {
    let fp = []  
    if (this.state.visit['vac'] === '2') {
      fp = this.state.products.filter(product => product['category'] === STOMA)
    }
    else {
      fp = this.state.products.filter(product => product['category'] !== STOMA)
    }
    this.setState({filterProducts:fp})
  }

  handleDeleteStockItem = () => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    this.setState({ uiLoading: true })

    for (const stockItemId of this.state.selectedRows) {
      axios
        .delete(`/api/stockItem/${stockItemId}`)
        .then(() => {
          const stockItems = this.state.stockItems.filter(
            (x) => x.id !== stockItemId
          )
          this.setState({ 
            stockItems, 
            uiLoading: false
          })
          // window.location.reload();
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.setState ({ login: true })
          }
          else {
            console.log(error)
          }
        })
    }
    this.setState({ deleteItemOn: false })
    this.setState({ selectedRows: null })
  }

  handleDeleteStockItems(visitId) {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    this.setState({ uiLoading: true })
    axios
      .get(`/api/stockBatch/${visitId}`)
      .then((response) => {
        if (response.data.length > 0) {
          axios
            .delete(`/api/stockBatch/${visitId}`)
            .then((response) => {
              this.setState({ uiLoading: false })
          })
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          console.log(error)
        }
      })
  }

  handleCreateOrUpdateClinic = () => {
    if (this.state.comment === '')
      return
    const clinic = {
      author: this.props.username,
      visitId: this.state.visitId,
      comment: this.state.comment,
      date: this.state.visit['date'],
    }

    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    const clinicId = this.state.clinic['id']
    let options = (clinicId === 0) 
      ? {
        url: '/api/clinic',
        method: 'post',
        data: clinic,
        }
      :
        {
          url: `/api/clinic/${clinicId}`,
          method: 'put',
          data: clinic,  
        }
      this.setState({ uiLoading: true })
      axios(options)
      .then((response) => {
        if (options.method === 'post') {
          this.setState({
            buttonText:'SAVE',
            clinic: response.data,
            editOpen: true,
            uiLoading: false,
          })
        } else {
          this.setState({
            displayClinic: false,
            editOpen: false,
            uiLoading: false,
          })
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          this.setState({ 
            editOpen: true,
              uiLoading: false
          })
          console.log(error)
        }
      })
  }

  openClinicEditor() {
    let clinic = {...clinic0}
    this.setState({
      displayClinic: true,
      editOpen: true,
      dbinfo:'',
      mouseX: null,
      mouseY: null,
      clinic
    })

    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }

    this.setState({ uiLoading: true })
    let buttonText = 'SUBMIT'
    axios
      .get(`/api/clinic/${this.state.visitId}`)
      .then((response) => {
        if (response.data.length > 0) {
          clinic = response.data[0]
          buttonText = 'SAVE'
        }
        this.setState({
          comment: clinic.comment,
          uiLoading: false, 
          buttonText, 
          clinic
        })
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          this.setState({ uiLoading: false })
          console.log(error)
        }
      })
    this.retrieveImageList(this.state.visitId)
  }

  handleDeleteClinic(visitId) {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    this.setState({uiLoading:true})
    axios
      .get(`/api/clinic/${visitId}`)
      .then((response) => {
        if (response.data.length > 0) {
          const clinicId = response.data[0].id
          axios
            .delete(`/api/clinic/${clinicId}`)
            .then(() => {
              this.setState({ clinic:clinic0, uiLoading:false })
            })
        }
      })
      .catch((error) => {
    		if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          console.log(error)
        }
      })
  }

  handleImageChange = (event) => {
    if (event.target.files[0] !== undefined) {
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      const filename = event.target.files[0].name;
      const extension = filename.split('.').pop();
      if (allowedExtensions.includes(extension)) {
          // Upload file to the server
          this.setState({
            image: event.target.files[0],
            imageError:false
          });    
      } else {
          // Handle error
          alert(`Please upload ${allowedExtensions.join(', ')} files`);
          this.setState({
            image:null,
            imageError:true
          });    
      }
    }
  };


  clinicImagesHandler = (event) => {
		event.preventDefault()
		this.setState({
			uiLoading: true,
      imageError: false
		});
		const authToken = localStorage.getItem('AuthToken')

    var self = this
    new Compressor(self.state.image, {
      quality: 0.6,
      width: 1200,
      height: 1600,
      success(result) {
        let formData = new FormData()
        formData.append('image', result, result.name)
        axios.defaults.headers.common = { Authorization: `${authToken}` }
        let images = self.state.images 
        axios
          .post(`/api/clinic/image/${self.state.visitId}/${self.state.title}`, formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then((response) => {
            const {id, imageUrl, title, visitId} = response.data
            const image =
              { id : id,
                imageUrl: imageUrl,
                title: title,
                visitId: visitId,
              }
            images.push(image)
            self.setState({
              editOpen: true,
              uiLoading: false,
              errors: response.data,
              title: 'Wound Solutions',
              images
            })
          })
          .catch((error) => {
            if (error.response.status === 403) {
              self.setState({ login: true })
            }
            else {
              self.setState ({ 
                imageError: error.response.data.error,
                uiLoading: false 
              })
              console.log(error)
            }
          })
      }
    })
	}


  handleCreateOrUpdateAssessment = () => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }

    const visitId = this.state.assessment['visitId']

    let options = {}
    let assessment = { ...this.state.assessment }
    if (visitId === '') {
      assessment['visitId'] = this.state.visitId
      options = {
        url: '/api/assessment',
        method: 'post',
        data: assessment,
      }
    } else {
      const id = assessment['id']
      delete assessment['id']
      options = {
        url: `/api/assessment/${id}`,
        method: 'put',
        data: assessment,
      }
    }
    this.setState({ uiLoading: true })
    axios(options)
      .then((response) => {
        if (options.method === 'post') {
          this.setState({
            assessment: response.data,
            buttonText:'SAVE',
            editOpen: true,
            uiLoading: false,
          })
        } else {
          this.setState({
            displayAssessment: false,
            editOpen: false,
            uiLoading: false,
          })
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          this.setState({
            editOpen: true,
            uiLoading: false,
          })
          console.log(error)
        }
      })
  }

  openAssessmentEditor() {

    let assessment = {...assessment0}
    this.setState({
      editOpen: true,
      dbinfo:'',
      mouseX: null,
      mouseY: null,
      displayAssessment: true,
      assessment
    })

    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }

    this.setState({ uiLoading: true })
    let buttonText = 'SUBMIT'
    axios
      .get(`/api/assessment/${this.state.visitId}`)
      .then((response) => {
        if (response.data.length > 0) {
          assessment = response.data[0]
          buttonText = 'SAVE'
        }
        this.setState({
          buttonText,
          uiLoading: false,
          assessment
        })
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          this.setState({ uiLoading: false })
          console.log(error)
        }
      })
  }

  openAuth() {
    console.log('[OpenAuth]')
    let buttonText = 'print PDF'
    this.setState({
      buttonText,
      editOpen: true,
      dbinfo:'',
      mouseX: null,
      mouseY: null,
      displayAuth: true,
    })
  }

  handleDeleteAssessment(visitId) {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }

    this.setState({ uiLoading: true })
    axios
      .get(`/api/assessment/${visitId}`)
      .then((response) => {
        if (response.data.length > 0) {
          const assessmentId = response.data[0].id
          axios
            .delete(`/api/assessment/${assessmentId}`)
            .then(() => {
              this.setState({ clinic:clinic0, uiLoading:false })
            })
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          console.log(error)
        }
      })
  }

  handleChangePatient = (patient) => {
    let visit = '' 
    if (patient !== null) {
      visit = {...this.state.visit}
      visit['patientName'] = patient['patientName']
      visit['firstName'] = patient['firstName']
      visit['SAID'] = patient['SAID']
      visit['medicalAid'] = patient['medicalAid']
      visit['memberNo'] = patient['memberNo']
    }
    else {
      visit = {...visit0} // classical side effect? This init doesn't matter with patient 
      visit.seenBy = this.props.username
    }
    this.setState({ visit })
  }

  handleChangeItem = (event) => {
    const stockItem = this.state.stockItem
    stockItem[`${event.target.name}`] = event.target.value
    this.setState({ stockItem })
  }

  handleChangeVisit = (event) => {
    const visit = this.state.visit
    visit[`${event.target.name}`] = event.target.value
    this.setState({ visit })
  }

  handleChangeAssessment = (event) => {
    const assessment = this.state.assessment
    if (event.target.type === 'checkbox') {
      assessment[`${event.target.name}`] = event.target.checked
    } else {
      assessment[`${event.target.name}`] = event.target.value
    }
    this.setState({ assessment })
  }

  handleDeleteImage = (imageId) => {
    for (const image of this.state.images) {
      if (image.id === imageId) {
                        // derive file name from imageUrl
        this.deleteSingleImage(image)
        return
      }
    }
  }

  deleteSingleImage = (image) => {
    let j = -10
    do {
      j -= 1
    } while(image.imageUrl.slice(j-1,j) !== '-' && j > -21)
    let imageFileName = image.visitId + image.imageUrl.slice(j-1,-10)
    // an ugly hack!
    imageFileName = imageFileName.replace('.','/')
    this.setState({ uiLoading: true })
    axios
      .delete(`/api/clinic/image/${image.id}/${imageFileName}`)
      .then(() => {
        const images = this.state.images.filter(m => m.id !== image.id)
        this.setState({ uiLoading:false, images })
      })
      .catch((error) => {
		if (error.response.status === 403) {
		  this.setState({ login: true })
		}
		else {
          console.log(error)
		}
      })
  }
  
  deletePhotoBatch = (visitId) => {
    axios
      .get(`/api/photoBatch/${visitId}`)
      .then((response) => {
        if (response.data.length > 0) {
          for (const image of response.data) {
            this.deleteSingleImage(image)
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          this.setState ({ 
            imageError: error.response.data,
          })
          console.log(error)
        }
      })
  }

  retrieveImageList = (visitId) => {
    if (this.state.images.length === 0) {
      this.setState({ 
        imageLoading: true, 
        imageError: false 
      })
      let images = []
      axios
        .get(`/api/photoBatch/${visitId}`)
        .then((response) => {
          if (response.data.length > 0) {
            images = response.data
          }
          this.setState({
            imageLoading: false, 
            images
          })
        })
        .catch((error) => {
		  if (error.response.status === 403) {
			this.setState({ login: true })
		  }
		  else {
			this.setState ({ 
			  imageError: error.response.data,
			  uiLoading: false 
			})
			console.log(error)
		  }
        })
    }
  }


  render() {
    if (this.state.login === true) {
      return <Redirect
        to={{ pathname: "/login" }}
      />
    }

    const { classes, ...rest } = this.props
    const { createOpen, deleteOpen, editOpen } = this.state

    const handleClose = (event) => {
      this.setState({
        createOpen: false,
        deleteOpen: false,
        displayAssessment: false,
        displayClinic: false,
        displayStockItems: false,
        displayVisit: false,
        displayAuth: false,
        editOpen: false,
     })
    }

    const stockItemColumns = [
      {
        field: 'product',
        headerName: 'Product Title',
        width: 400,
        editable: false,
      },
      {
        field: 'nappiCode',
        headerName: 'Nappi Code',
        width: 200,
        editable: false,
      },
      {
        field: 'quantity',
        headerName: 'Quantity',
        width: 150,
        editable: true,
      },
    ]

    const visitColumns = [
      {
        field: 'date',
        headerName: 'Date',
        width: 100,
        editable: false,
      },
      {
        field: 'patientName',
        headerName: 'Surname',
        width: 140,
        editable: false,
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        width: 140,
        editable: false,
      },
      {
        field: 'SAID',
        headerName: 'ID/Passport',
        width: 130,
        editable: false,
      },
      {
        field: 'medicalAid',
        headerName: 'Medical Aid',
        width: 120,
        editable: false,
      },
      {
        field: 'memberNo',
        headerName: 'Membership No.',
        width: 130,
        editable: false,
      },
      {
        field: 'home',
        headerName: 'Home',
        width: 110,
        editable: false,
				valueGetter: (params) => (
          params.value === '1' ? 'Home' :
          params.value === '2' ? 'Clinic' :
          params.value === '3' ? 'Hospital' : 
          params.value === '4' ? 'Other' : 
           'error'  
        )
      },
      {
        field: 'vac',
        headerName: 'vac',
        width: 110,
        editable: false,
        valueGetter: (params) => ( 
          params.value === '1' ? 'Conventional' : 
          params.value === '101' ? 'Genadyne' : 
          params.value === '105' ? 'KCI' : 
          params.value === '104' ? 'Smith&Nephew' : 
          params.value === '7' ? 'Vertice' :
          'error'
        )  
      },
      {
        field: 'seenBy',
        headerName: 'Seen by',
        width: 110,
        editable: false,
      },
      {
        field: 'admnotes',
        headerName: 'Admin Notes',
        width: 180,
        editable: false,
      },
      {
        field: 'notes',
        headerName: 'Notes',
        width: 120,
        editable: false,
      },
      {
        field: 'admin',
        headerName: 'Consumer Fee',
        width: 90,
        editable: false,
      },
    ]

    let clinicImages = [];
    if (this.state.images.length) {
        clinicImages = 
          <ImageList rowHeight={250} className={classes.imageList} cols={3}>
            {this.state.images.map((image) => (
              <ImageListItem key={image.id} cols={image.cols || 1}>
                <img src={image.imageUrl} alt={image.title} />
                <ImageListItemBar
                  title={image.title}
                  actionIcon={
                    <IconButton
                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      onClick={() => this.handleDeleteImage(image.id)}                    
                    >
                      <DeleteIcon />
                    </IconButton>
                  }      
                />
            </ImageListItem>
            ))}
          </ImageList>
      }

    const Error =
      this.state.error === false ? (
        <div className={classes.subTitle}>&nbsp;VISITS</div>
      ) : (
        <div className={classes.error}>Wrong Input!</div>
    )

    const clickToAddVisitButton = (
      <IconButton
        className={classes.addButton}
        color="action"
        aria-label="Add a visit"
        onClick={this.handleClickCreateVisit}
        size="large">
        <AddCircleIcon style={{ fontSize: 60 }} />
      </IconButton>
    )
  
    const clickToDeleteVisitButton = (
      <IconButton
      className={classes.deleteButton}
      color="action"
      aria-label="delete visit"
      onClick={this.confirmDeleteVisit}
      size="large">
      <DeleteIcon style={{ fontSize: 60 }} />
    </IconButton>
    )

  
    const clickToEditVisitButton = (
      <Button 
        variant="contained"
        aria-label="visit page"
        color="action" 
        startIcon={<EditIcon />}
        onClick={this.openVisitEditor}
        size="small"
      >
        Visit
      </Button>
    )
  
    const clickToEditStockItemsButton = (
      <Button
        variant="contained"
        color="action" 
        startIcon={<InventoryIcon />}
        onClick={this.openStockItemEditor}
        size="small"
      >
        Stock S.
      </Button>
    )
  
    const clickToEditClinicalButton = (
      <Button
        variant="contained"
        color="action"
        startIcon={<ShowChartIcon />}
        onClick={this.openClinicEditor}
        size="small"
      >
        Clinical
      </Button>
    )
  
    const clickToEditAssessmentButton = (
      <Button
        variant="contained"
        color="action"
        startIcon={<HealingIcon />}
        onClick={this.openAssessmentEditor}
        size="small"
      >
        Wound Ass.
      </Button>
    )

    const clickToAuthButton = (
      <Button
        variant="contained"
        color="action"
        startIcon={<AdfScannerIcon />}
        onClick={this.openAuth}
        size="small"
      >
        Auth.
      </Button>
    )

    const dbinfo = this.state.dbinfo !== '' ? (
      <Button
        className={classes.floatingButton}
        color="action"
      >
        {this.state.dbinfo}  
      </Button>
    ) : ''
  
   
    const subMenuButtons = this.state.displaySubMenuButtons ? (
      <div>
        {clickToAddVisitButton}
        {clickToEditVisitButton}
        {clickToEditStockItemsButton}
        {clickToEditClinicalButton}
        {clickToEditAssessmentButton}
        {/* {clickToAuthButton} */}
        {clickToDeleteVisitButton}
        {dbinfo}
      </div>
    ) : (
      <div>
        {clickToAddVisitButton}
        {dbinfo}
      </div>
    )
  
  
    const handleCellEditStart = (e) => {
      const stockItem = {...e.row}
      this.setState({stockItem})
    }

    const handleCellEditCommit = (event) => {
      const stockItem = {...this.state.stockItem}
      stockItem.quantity = event.value
      this.handleUpdateStockItem(stockItem)
    }

    // const handleVisitSelectionChange = (e) => {
    //   this.setState({ deleteVisitOn: e.length > 0 })
    //   this.setState({ selectedRows: e })
    // }

    const handleItemSelectionChange = (e) => {
      this.setState({ deleteItemOn: e.length > 0})
      this.setState({ selectedRows: e})
    }

    const handleDoubleClick = (event) => {
      let mouseX = null
      let mouseY = null
      if (this.state.filterOff === true ) {
        mouseX = this.state.mouseX ? null : event.clientX - 2
        mouseY = this.state.mouseY ? null : event.clientY - 4
      }
      this.setState({
        displaySubMenuButtons:0,
        mouseX, 
        mouseY
      })
    }

    // const handleRightClick = (event) => {
    //   event.preventDefault()
    //   this.setState({
    //     mouseX: event.clientX - 2,
    //     mouseY: event.clientY - 4,
    //   })
    // }

    const handleSingleClick = (event) => {
      this.setState({
        dbinfo: '',
        displaySubMenuButtons:1
      })
    }

    const handleCMenuClose = () => {
      this.setState({
        mouseX: null,
        mouseY: null,
        displaySubMenuButtons:0
      })
    }

    const fetchKey = (event) => {
      if (this.state.visitId !== event.id) {
        this.setState({
          assessment:0,
          clinic:clinic0,
          images:[],
          stockItems:[],
          visitId: event.id,
          visit: event.row,
        })
      }
    }

    const contextMenu = (
        <Menu
          keepMounted
          open={this.state.mouseY !== null}
          onClose={handleCMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            this.state.mouseY !== null && this.state.mouseX !== null
              ? { top: this.state.mouseY, left: this.state.mouseX }
              : undefined
          }
          // PaperProps={{
          //   style: {
          //     marginTop: "50px",
          //     backgroundColor: "#F4F3F0"
          //   }
          // }}
        >
          <MenuItem onClick={this.openVisitEditor}>Visit Info</MenuItem>
          <MenuItem onClick={this.openStockItemEditor}>Stock Sheet</MenuItem>
          <MenuItem onClick={this.openClinicEditor}>Clinical Record</MenuItem>
          <MenuItem onClick={this.openAssessmentEditor}>Wound Assessment</MenuItem>
          {/* <MenuItem onClick={this.openAuth}>Authorize</MenuItem> */}
          <MenuItem >---------------</MenuItem>
          <MenuItem onClick={this.confirmDeleteVisit}>Delete Visit</MenuItem>
       </Menu>
      )      


    const toolbarTitle = (
      this.state.displayStockItems
      ? 'Update Stock Sheet'
      : this.state.displayClinic
      ? 'Update Clinical Record'
      : this.state.displayAssessment
      ? 'Update Wound Assessment'
      : this.state.displayVisit 
      ? 'Update Visit'
      : this.state.displayAuth
      ? 'Authorize'
      : ''
    )

    const toolbarButton = ( 
      this.state.displayStockItems
      ?
      <Button
        color="inherit"
        onClick={handleClose}
        className={classes.submitButton}
      >
        {this.state.buttonText}
      </Button>
      : this.state.displayClinic 
      ?
        <Button
          color="inherit"
          onClick={this.handleCreateOrUpdateClinic}
          className={classes.submitButton}
        >
          {this.state.buttonText}
        </Button>
      : this.state.displayAssessment 
      ?
        <Button
          color="inherit"
          onClick={this.handleCreateOrUpdateAssessment}
          className={classes.submitButton}
        >
          {this.state.buttonText}
        </Button>
      : this.state.displayAuth
      ?
        <Button
          color="inherit"
          onClick={this.handleCreateOrUpdateAssessment}
          className={classes.submitButton}
        >
          {this.state.buttonText}
        </Button>
      : this.state.displayVisit
      ?
      <Button
        color="inherit"
        onClick={this.handleUpdateVisit}
        className={classes.submitButton}
      >
        {this.state.buttonText}
      </Button>
      : ''
    )    

    const toolbarSubs = (
      <Toolbar variant="dense">
        <IconButton
          autoFocus
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          size="large">
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {toolbarTitle}
        </Typography>
        {toolbarButton}
      </Toolbar>
    )

    const DeleteDialog = (
      <Dialog
        open={deleteOpen}
        onClose={handleClose}
      >
        <DialogContent>
          <DialogContentText>
              Please confirm this visit shall be deleted or cancel otherwise. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={this.handleDeleteVisit}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )

    const ClickToDeleteStockItemButton = 
      this.state.deleteItemOn ? 
      (
        <IconButton
          className={classes.deleteButton}
          color="primary"
          aria-label="delete stock item"
          onClick={this.handleDeleteStockItem}
          size="large">
          <DeleteIcon style={{ fontSize: 60 }} />
        </IconButton>
      ) 
      : ('')

    // Update visit info:
    const visitView = this.state.displayVisit ? (
      <div>
        <form className={classes.form} noValidate>
          <Grid container item xs={12} spacing={1} >
            <Grid item xs={4}>
              <TextField
                variant="standard"
                id="home"
                label="Location"
                name="home"
                value={this.state.visit['home']}
                onChange={this.handleChangeVisit}
                select
              >
                <MenuItem value="1">Home</MenuItem>
                <MenuItem value="2">Clinic</MenuItem>
                <MenuItem value="3">Hospital</MenuItem>
                <MenuItem value="4">Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="standard"
                id="vac"
                label="Category"
                name="vac"
                value={this.state.visit['vac']}
                onChange={this.handleChangeVisit}
                select
              >
                <MenuItem value="1">Conventional</MenuItem>
                <MenuItem value="101">Genadyne</MenuItem>
                <MenuItem value="105">KCI</MenuItem>
                <MenuItem value="104">Smith&Nephew</MenuItem>
                <MenuItem value="7">Vertice</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="standard"
                id="admin"
                label="Consumer Fee"
                name="admin"
                value={this.state.visit['admin']}
                onChange={this.handleChangeVisit}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="standard"
                id="seenBy"
                label="Sr Seen By"
                name="seenBy"
                value={this.state.visit['seenBy']}
                onChange={this.handleChangeVisit}
              />
            </Grid>
          </Grid>
          <div className={classes.formSeparator1}>
            Notes, Reminders, Queries
            <TextareaAutosize
              style={{ width: '96%', fontSize: '14pt' }}
              minRows={3}
              maxRows={3}
              placeholder="type here..."
              name="notes"
              value={this.state.visit['notes']}
              onChange={this.handleChangeVisit}
            />
          </div>
          <div className={classes.formSeparator1}>
            Admin Notes
            <TextareaAutosize
              style={{ width: '96%', fontSize: '14pt' }}
              minRows={3}
              maxRows={3}
              placeholder="type here..."
              name="admnotes"
              value={this.state.visit['admnotes']}
              onChange={this.handleChangeVisit}
            />
          </div>
        </form>
      </div>
    ) : (
      ''
    )

    // Add/Delete stockItems per visit:
    const stockItemView = this.state.displayStockItems ? (
      <div>
        <Autocomplete 
          className={clsx(classes.content, classes)}
          id="products"
          autoHighlight
          variant="standard"
          options={this.state.filterProducts}
          getOptionLabel={(option) => option.title + ', ' + option.nappiCode}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Products"
              variant="outlined"
            />
          )}
          onChange={(event, product) => {
            this.handleCreateStockItem(product, this.state.visitId)
          }}
        />
        {this.state.productLoading && (
          <CircularProgress 
            size={40} 
            className={classes.pprogress} 
          />
        )}
        <div style={{ width: '100%', height:'380pt', backgroundColor:'#ffffff' }}>
          <DataGrid
            rows={this.state.stockItems}
            columns={stockItemColumns}
            onCellEditCommit={handleCellEditCommit}
            onCellEditStart={handleCellEditStart}
            isRowSelectable={() => true}
//            checkboxSelection   
            onSelectionModelChange={handleItemSelectionChange}
          />
        </div>
        {ClickToDeleteStockItemButton}
      </div>
    ) : (
      ''
    )

    // Update clinical record entry per visit:
    const clinicView = this.state.displayClinic ? (
      <div>
        <p/>
        <Typography variant="h6" className={classes.uploadTitle}>
          &nbsp;&nbsp;&nbsp;Enter clinical info:
        </Typography>
        <form className={classes.form} noValidate>
            <TextareaAutosize
              style={{ width: '100%', fontSize: '14pt' }}
              maxRows={4}
              minRows={3}
              aria-label="comment"
              name="comment"
              value={this.state.comment}
              onChange={(e) => this.setState({comment:e.target.value})}
            />
        </form>
        <Divider />
        <Card {...rest} className={classes.card}>
          <CardContent>
            <Typography variant="h6" className={classes.uploadTitle}>
              Image Upload:
            </Typography>
            <p/>
            <div>
              <TextField
                className={classes.ImageTitle}
                variant="outlined"
                id="title"
                label="Image Title"
                name="title"
                value={this.state.title}
                onChange={(e) => this.setState({ title: e.target.value })}
              />
              <p/>
              <input
                className={classes.fileUpload} 
                id="file-upload" 
                type="file" 
                onChange={this.handleImageChange} 
              />            
              {this.state.imageError ? (
                <Typography variant="h6" className={classes.error}>
                    {this.state.imageError}
                </Typography>
              ) : (
                ''
              )}
              <p/>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="small"
                startIcon={<CloudUploadIcon />}
                className={classes.uploadButton}
                onClick={this.clinicImagesHandler}
              >
                Upload Photo
              </Button>
            </div>
          </CardContent>
        </Card>
        <div>
          {clinicImages}
          {this.state.imageLoading && (
            <CircularProgress 
              size={75} 
              className={classes.uiProgess} 
              style={{left:'45%'}}
            />
          )}
        </div>
      </div>
    ) : (
      ''
    )

    // Update wound assessment data per visit
    const assessmentView = this.state.displayAssessment ? (
      <form className={classes.form} noValidate>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={4}>
            <TextField
              variant="standard"
              id="weight"
              label="Weight (kg)"
              name="weight"
              value={this.state.assessment['weight']}
              onChange={this.handleChangeAssessment}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              variant="standard"
              id="allergies"
              label="Allergies"
              name="allergies"
              value={this.state.assessment['allergies']}
              onChange={this.handleChangeAssessment}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="standard"
              id="smoking"
              label="Years of smoking"
              name="smoking"
              value={this.state.assessment['smoking']}
              onChange={this.handleChangeAssessment}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="standard"
              id="nutrition"
              label="Nutrition Status"
              name="nutrition"
              value={this.state.assessment['nutrition']}
              onChange={this.handleChangeAssessment}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="standard"
              id="mobility"
              label="Mobility Status"
              name="mobility"
              value={this.state.assessment['mobility']}
              onChange={this.handleChangeAssessment}
            />
          </Grid>
        </Grid>
        <div className={classes.formSeparator1}>
          Co-Morbidities
          <TextareaAutosize
            style={{ width: '100%', fontSize: '14pt' }}
            maxRows={1}
            placeholder="type here..."
            name="comorbidities"
            value={this.state.assessment['comorbidities']}
            onChange={this.handleChangeAssessment}
          />
        </div>
        <div className={classes.formSeparator1}>
          Medications
          <TextareaAutosize
            style={{ width: '100%', fontSize: '14pt' }}
            maxRows={3}
            placeholder="type here..."
            name="medications"
            value={this.state.assessment['medications']}
            onChange={this.handleChangeAssessment}
          />
        </div>
        <div className={classes.formSeparator1}>
          Wound History
          <TextareaAutosize
            style={{ width: '100%', fontSize: '14pt' }}
            maxRows={4}
            placeholder="type here..."
            name="medInfo"
            value={this.state.assessment['medInfo']}
            onChange={this.handleChangeAssessment}
          />
        </div>
        <div className={classes.formSeparator1}>
          Factors affecting wound healing
          <TextareaAutosize
            style={{ width: '100%', fontSize: '14pt' }}
            maxRows={4}
            placeholder="type here..."
            name="healingFactors"
            value={this.state.assessment['healingFactors']}
            onChange={this.handleChangeAssessment}
          />
        </div>

        <div>
          <Grid container>
            <Grid container item xs={12}>
              <p className={classes.formSeparator2}>Wound Description</p>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.assessment['postsurgical']}
                      name="postsurgical"
                      disableRipple
                      onChange={this.handleChangeAssessment}
                    />
                  }
                  label={'Postsurgical'}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.assessment['infection']}
                      name="infection"
                      disableRipple
                      onChange={this.handleChangeAssessment}
                    />
                  }
                  label={'Infection'}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.assessment['inflammation']}
                      name="inflammation"
                      disableRipple
                      onChange={this.handleChangeAssessment}
                    />
                  }
                  label={'Inflammation'}
                />
              </Grid>
            </Grid>


            <Grid container item xs={12}>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  id="site"
                  label="Wound Location"
                  name="site"
                  value={this.state.assessment['site']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  id="type"
                  label="Wound Type"
                  name="type"
                  value={this.state.assessment['type']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  id="treatment"
                  label="Previous Treatments"
                  name="treatment"
                  value={this.state.assessment['treatment']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
                <p className={classes.formSeparator2}>Wound Size(s)</p>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={3}>
                <TextField
                  variant="standard"
                  id="length"
                  label="length (cm)"
                  name="length"
                  value={this.state.assessment['length']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="standard"
                  id="width"
                  label="width (cm)"
                  name="width"
                  value={this.state.assessment['width']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="standard"
                  id="depth"
                  label="depth (cm)"
                  name="depth"
                  value={this.state.assessment['depth']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={3}>
                <TextField
                  variant="standard"
                  id="length2"
                  label="length (cm)"
                  name="length2"
                  value={this.state.assessment['length2']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="standard"
                  id="width2"
                  label="width (cm)"
                  name="width2"
                  value={this.state.assessment['width2']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="standard"
                  id="depth2"
                  label="depth (cm)"
                  name="depth2"
                  value={this.state.assessment['depth2']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={3}>
                <TextField
                  variant="standard"
                  id="length3"
                  label="length (cm)"
                  name="length3"
                  value={this.state.assessment['length3']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="standard"
                  id="width3"
                  label="width (cm)"
                  name="width3"
                  value={this.state.assessment['width3']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="standard"
                  id="depth3"
                  label="depth (cm)"
                  name="depth3"
                  value={this.state.assessment['depth3']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
            <p />
            <p className={classes.formSeparator2}>Wound Assessment</p>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  id="balance"
                  label="Moisture Balance"
                  name="balance"
                  value={this.state.assessment['balance']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  id="margin"
                  label="Epithelial Margin"
                  name="margin"
                  value={this.state.assessment['margin']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  id="viability"
                  label="Tissue Viability"
                  name="viability"
                  value={this.state.assessment['viability']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  id="exudateType"
                  label="Exudate Type"
                  name="exudateType"
                  value={this.state.assessment['exudateType']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  id="exudateLevel"
                  label="E.Level"
                  name="exudateLevel"
                  value={this.state.assessment['exudateLevel']}
                  onChange={this.handleChangeAssessment}
                  select
                >
                  <MenuItem value='1'>Dry</MenuItem>
                  <MenuItem value='2'>Low</MenuItem>
                  <MenuItem value='3'>Medium</MenuItem>
                  <MenuItem value='4'>High</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  id="painLevel"
                  label="Pain Measurement"
                  name="painLevel"
                  value={this.state.assessment['painLevel']}
                  onChange={this.handleChangeAssessment}
                />
              </Grid>
              <Grid item xs={8}>
                <p>0-1: no pain / 2-6: moderate / 7-10: severe</p>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.formSeparator1}>
          Texas Classification
          <TextareaAutosize
            style={{ width: '100%', fontSize: '14pt' }}
            maxRows={1}
            placeholder="type here..."
            name="texas"
            value={this.state.assessment['texas']}
            onChange={this.handleChangeAssessment}
          />
        </div>
        <div className={classes.formSeparator1}>
          C.E.A.P.
          <TextareaAutosize
            style={{ width: '100%', fontSize: '14pt' }}
            maxRows={1}
            placeholder="type here..."
            name="ceap"
            value={this.state.assessment['ceap']}
            onChange={this.handleChangeAssessment}
          />
        </div>
        <div className={classes.formSeparator1}>
          T.B.S.A
          <TextareaAutosize
            style={{ width: '100%', fontSize: '14pt' }}
            maxRows={1}
            placeholder="type here..."
            name="tbsa"
            value={this.state.assessment['tbsa']}
            onChange={this.handleChangeAssessment}
          />
        </div>
        <div className={classes.formSeparator1}>
          Treatment Plan
          <TextareaAutosize
            style={{ width: '100%', fontSize: '14pt' }}
            maxRows={1}
            placeholder="type here..."
            name="treatmentPlan"
            value={this.state.assessment['treatmentPlan']}
            onChange={this.handleChangeAssessment}
          />
        </div>
        <div className={classes.formSeparator1}>
          Health Education
          <TextareaAutosize
            style={{ width: '100%', fontSize: '14pt' }}
            maxRows={1}
            placeholder="type here..."
            name="healthEducation"
            value={this.state.assessment['healthEducation']}
            onChange={this.handleChangeAssessment}
          />
        </div>
      </form>
    ) : (
      ''
    )

    const authView = this.state.displayAuth ? (
      <Dialog
        fullScreen
        open={editOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
            style: {
              backgroundColor: 'dialog',
              boxShadow: 'none',
            }
          }}
        >
          <AppBar position="sticky">
            <Toolbar 
              variant="dense"
            >
              <IconButton
                autoFocus
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                size="large">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
      </Dialog>
    ) : (
      ''
    )
    const vHeight = this.state.hMatches ? PHONEHEIGHT : TABHEIGHT
    const vWidth = this.state.wMatches ? PHONEWIDTH : TABWIDTH

    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {this.state.uiLoading && (
          <CircularProgress 
            size={150} 
            className={classes.uiProgess} 
            style={{left:'25%'}}
          />
        )}
        <div>  
          {Error}
          {subMenuButtons}
        </div>
        <div
          // onContextMenu={handleRightClick}
          onDoubleClick={handleDoubleClick}
          onClick={handleSingleClick}
          style={{ cursor: 'context-menu' }}
        >
          {contextMenu}
          {/* ### DataGrid to display available visits */}
          <div style={{ height: `${vHeight}pt`, width: `${vWidth}pt` }}>
            <DataGrid
              className={classes.visitGrid}
              rows={this.state.visits}
              columns={visitColumns}
              isRowSelectable={() => true}
              // onFilterModelChange={filterOn}
              // checkboxSelection
              // disableSelectionOnClick
              // onSelectionModelChange={handleVisitSelectionChange}
              // onRowEnter={fetchKey}
              onCellClick={fetchKey}
              // onCellDoubleClick={this.openVisitEditor}
            />
          </div>
        </div>
        {/* "Create-Visit" Dialog */}
        <Dialog
          fullScreen
          open={createOpen} 
          onClose={handleClose} 
          TransitionComponent={Transition} 
          PaperProps={{
            style: {
              backgroundColor: 'dialog',
              boxShadow: 'none',
            }
          }}
        >
          <AppBar position="sticky">
            <Toolbar 
              variant="dense"
            >
              <IconButton
                autoFocus
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                size="large">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {'Create a new visit'}
              </Typography>
              <Button
//                  autoFocus
                color="inherit"
                onClick={this.handleCreateVisit}
                className={classes.submitButton}
              >
                {'Submit'}
              </Button>
            </Toolbar>
          </AppBar>
          {/* Small form to add patient details to visit: */}
          <form className={classes.form} noValidate>
            <Grid container>
              {/* <Grid item xs={9} sm={12}> */}
                <Autocomplete
                  id="patients"
                  autoHighlight
                  options={this.state.patients}
                  getOptionLabel={(option) => (option.patientName + ', ' + option.firstName + ', ' + option.SAID)}
                  style={{ width: 400 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="choose Patient"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, patient) => {
                    this.handleChangePatient(patient)
                  }}
                />
              {/* </Grid> */}
            </Grid>
            <p/>
            <Grid container>
              <Grid item xs={6} sm={12}>
                <TextField
                  variant="outlined"
                  id="vDate"
                  label=""
                  helperText="Date of Visit"
                  type="date"
                  name="vDate"
                  value={this.state.vDate}
                  onChange={(e) => this.setState({ vDate: e.target.value })}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6}>
                <TextField
                  variant="filled"
                  disabled
                  id="patientName"
                  label=""
                  helperText="Name"
                  name="patientName"
                  value={this.state.visit['patientName']}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="filled"
                  disabled
                  id="SAID"
                  label=""
                  helperText="ID/Passport number"
                  name="SAID"
                  value={this.state.visit['SAID']}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6}>
                <TextField
                  variant="filled"
                  disabled
                  id="medicalAid"
                  label=""
                  helperText="Medical Aid"
                  name="medicalAid"
                  value={this.state.visit['medicalAid']}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="filled"
                  disabled
                  id="memberNo"
                  label=""
                  helperText="Member Number"
                  name="memberNo"
                  value={this.state.visit['memberNo']}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1} >
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  id="home"
                  label="Location"
                  name="home"
                  value={this.state.visit['home']}
                  onChange={this.handleChangeVisit}
                  select
                >
                  <MenuItem value="1">Home</MenuItem>
                  <MenuItem value="2">Clinic</MenuItem>
                  <MenuItem value="3">Hospital</MenuItem>
                  <MenuItem value="4">Other</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  id="vac"
                  label="Con/Vac"
                  name="vac"
                  value={this.state.visit['vac']}
                  onChange={this.handleChangeVisit}
                  select
                >
                  <MenuItem value="1">Conventional</MenuItem>
                  <MenuItem value="101">Genadyne</MenuItem>
                  <MenuItem value="105">KCI</MenuItem>
                  <MenuItem value="104">Smith&Nephew</MenuItem>
                  <MenuItem value="7">Vertice</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1} >
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  id="admin"
                  label="Consumer Fee"
                  name="admin"
                  value={this.state.visit['admin']}
                  onChange={this.handleChangeVisit}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  id="seenBy"
                  label="Sr Seen By"
                  name="seenBy"
                  value={this.state.visit['seenBy']}
                  onChange={this.handleChangeVisit}
                />
              </Grid>
            </Grid>
            <div className={classes.formSeparator1}>
              <Typography variant="h6" className={classes.title}>
                {'Visit Notes'}
              </Typography>
              <TextareaAutosize
                style={{ width: '100%', fontSize: '14pt' }}
                minRows={5}
                maxRows={5}
                placeholder="type your visit notes here..."
                name="notes"
                value={this.state.visit['notes']}
                onChange={this.handleChangeVisit}
              />
            </div>
            <div className={classes.formSeparator1}>
              <Typography variant="h6" className={classes.title}>
                {'Admin Notes'}
              </Typography>
              <TextareaAutosize
                style={{ width: '100%', fontSize: '14pt' }}
                minRows={5}
                maxRows={5}
                placeholder="type your Admin notes here..."
                name="admnotes"
                value={this.state.visit['admnotes']}
                onChange={this.handleChangeVisit}
              />
            </div>
          </form>
        </Dialog>
        {/* Overlay to update visit, stock sheet, clinical record or wound assessment */}
        <Dialog 
          fullScreen
          // className={classes.dialogStyle}
          open={editOpen} 
          onClose={handleClose} 
          TransitionComponent={Transition} 
          PaperProps={{
            style: {
              backgroundColor: '#ffefe0',
              boxShadow: 'none',
            }
          }}
        >
          <AppBar position="sticky">
            {toolbarSubs}
          </AppBar>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  className={classes.textField}
                  disabled
                  inputProps={{style: {backgroundColor: "#ffefe0", fontSize: 11}}}
                  InputLabelProps={{style: {fontSize: 10}}} 
                  variant="filled"
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  value={this.state.visit['firstName']}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{style: {backgroundColor: "#ffefe0", fontSize: 11}}}
                  InputLabelProps={{style: {fontSize: 10}}} 
                  disabled
                  variant="filled"
                  id="patientName"
                  label="Last Name"
                  name="patientName"
                  value={this.state.visit['patientName']}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{style: {backgroundColor: "#ffefe0", fontSize: 11}}}
                  InputLabelProps={{style: {fontSize: 10}}} 
                  disabled
                  variant="filled"
                  id="SAID"
                  label="SA ID"
                  name="SAID"
                  value={this.state.visit['SAID']}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  inputProps={{style: {backgroundColor: "#ffefe0", fontSize: 11}}}
                  InputLabelProps={{style: {fontSize: 10}}} 
                  disabled
                  variant="filled"
                  id="memberNo"
                  label="Membership No."
                  name="memberNo"
                  value={this.state.visit['memberNo']}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{style: {backgroundColor: "#ffefe0", fontSize: 11}}}
                  InputLabelProps={{style: {fontSize: 10}}} 
                  disabled
                  variant="filled"
                  id="medicalAid"
                  label="Medical Aid"
                  name="medicalAid"
                  value={this.state.visit['medicalAid']}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{style: {backgroundColor: "#ffefe0", fontSize: 11}}}
                  InputLabelProps={{style: {fontSize: 10}}} 
                  disabled
                  variant="filled"
                  id="date"
                  label="Date of Visit"
                  name="date"
                  value={this.state.visit['date']}
                />
              </Grid>
            </Grid>
          {this.state.uiLoading && (
            <CircularProgress 
              size={75} 
              className={classes.uiProgess} 
              style={{left:'45%'}}
            />
          )}
          {visitView} {stockItemView} {clinicView} {assessmentView} {authView}
        </Dialog>
        {DeleteDialog}
      </main>
    );
  }
}

export default withStyles(styles)(visit)
