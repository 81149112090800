import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'   // ...das muss so aussehen!
import { refreshToken } from './refresh.js';

import Logo from './logo.js'

import { withStyles } from '@mui/styles'
import {
  AppBar,
  Box,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'


import { DataGrid } from '@mui/x-data-grid'

import CloseIcon from '@mui/icons-material/Close'
import LaunchIcon from '@mui/icons-material/Launch'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'

import axios from 'axios'

import { green, grey, red } from '@mui/material/colors'


const AUTHORIZE = 1
const INVOICE = 2
const TREATCODE88100 = '88100 - R266.70 - home visit'
const TREATCODE88002 = '88002 - R540.50 - consultation, wound assessment, and counseling'
const TREATCODE88041 = '88041 - R205.60 - treatment of extensive wounds, charged on every visit'


const styles = (theme) => ({
  a: {
    fontSize: '13pt',
    color: '#fff',
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    background: '#e597ba',
    borderRadius: '8px',
    position: 'absolute',
    right: 10,
  },
  article0: {
    background: 'white',
    color: 'black',
    fontSize: '13pt',
    marginLeft:'80px',
    maxWidth: 1000,
    padding: '35px 15px 10px 15px',
    width: '90%',
  },
  article1: {
    background: 'white',
    color: 'black',
    fontSize: '13pt',
    marginLeft:'80px',
    maxWidth: 1000,
    padding: '30px 15px 20px 15px',
    width: '90%',
  },
  article2: {
    background: 'white',
    border: '1px solid #4d4e51',
    color: 'black',
    fontSize: '13pt',
    marginLeft:'80px',
    maxWidth: 1000,
    padding: '10px 15px 0px 15px',
    width: '90%',
  },
  article3: {
    border: '1px solid #5a5d63',
    color: 'black',
    fontSize: '13pt',
    marginTop: '20px',
    marginLeft:'80px',
    maxWidth: 1000,
    padding: '5px 15px 5px 15px',
    width: '90%',
  },  
  article4: {
    background: 'white',
    color: 'black',
    fontSize: '13pt',
    marginLeft:'80px',
    maxWidth: 1000,
    padding: '50px 15px 5px 15px',
    width: '90%',
  },
  butto: {
    background: '#e597ba',
    border: '1px solid #4d4e51',
    borderRadius: '8px',
    color: '#fff',
    padding: '10px',
    fontSize: '14pt',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#ffbfdb',
      color: '#3c52b2',
      cursor: 'pointer'
    },    
    position: 'absolute',
    right: 10,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  dateString: {
    textAlign: 'end',
    fontSize: '13pt',
  },
  divider: {
    border: '1px solid #000000',
    color: '#000000',
  },
  error: {
    fontSize: '20px',
    color: red[400],
  },
  finePrint: {
    fontSize: '11pt',
    color: 'black',
  },
  finePrint2: {
    fontSize: '11pt',
    color: 'black',
  },
  form: {
    width: '90%',
    marginLeft: 13,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: 14,
  },
  imageContainer: {
    marginTop: '5rem',
    display: 'block',
    pageBreakBefore: 'always',
  },
  imageRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    marginLeft:'60px',
    width: 1000,
    height: 1500,
  },
  poptitle: {
    marginLeft: theme.spacing(2),
    width: 800,
  },
  pos: {
    marginBottom: 12,
  },
  printButton: {
    //		position: 'fixed',
    bottom: 0,
    left: 0,
    color: green
  },
  prodGrid: {
    color: grey[900],
    background: '#edf0f7',
  },
  prodGridInv: {
    width: 1000,
    // height: 756,

    // '& .MuiDataGrid-main': {
    //   background: '#f4f7ff',
    //   border: '1px solid #aaa',
    // },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: "#2b4287",
      color: "#f4f7ff",
      fontSize: 13  
    },
    // "& .MuiDataGrid-renderingZone": {
    //       maxHeight: "none !important"
    // },
    // "& .MuiDataGrid-cell": {
    //   lineHeight: "unset !important",
    //   maxHeight: "none !important",
    //   whiteSpace: "normal"
    // },
    // "& .MuiDataGrid-row": {
    //   maxHeight: "unset!important"
    // }   
  },  
  selectButton: {
    //		position: 'fixed',
    bottom: 0,
    right: 0,
  },
  subTitle: {
    fontSize: '20px',
    width: 400,
    color: grey[700],
    margin: '54px 0px 0px 0px',
    // ConsultUs
    // background: 'linear-gradient(45deg, #EEE 30%, #DDD 90%)',
    // Wound Solutions
    background: 'linear-gradient(45deg, #f2f0ea 30%, #b3acc1 90%)',
    borderRadius: '4px',
  },
  table: {
    marginLeft:'60px',
    maxWidth: 1000,
    background: '#edf0f7',
  },
  toolbar: theme.mixins.toolbar,
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  upAndBold: {
    textAlign: 'left',
    fontSize: '15pt',
    fontWeight: 'bold',
  },
  upAndLeft: {
    textAlign: 'left',
    fontSize: '12pt',
  },
  upAndRight: {
    fontSize: '12pt',
    textAlign: 'end',
  },
  upAndUnderline: {
    fontSize: '15pt',
    fontWeight: 'bold',
    textAlign: 'end',
    textDecoration: 'underline'
  },
  visitGrid: {
    color: grey[900],
    // ConsultUs
    // background: 'linear-gradient(45deg, #e5eae8f0 30%, #afc9bed0 90%)',
    // Wound Solutions
    background: 'linear-gradient(45deg, #b3acc1 100%, #eeeae3 100%)'
  },
})

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
))

const ICD10CODES = 'Z48.0, T81.4, Y83.8'
const TARIFFCODE = '88301'
const assessment0 = {
  allergies: '',
  balance: 'unknown',
  comorbidities: '',
  depth: 1,
  education: '',
  exudateLevel: '1',
  exudateType: '',
  healingFactors: '',
  healthEducation: '',
  infection: false,
  inflammation: false,
  length: 1,
  margin: 'unknown',
  medInfo: '',
  medications: '',
  mobility: '',
  nutrition: '',
  painLevel: 0,
  postsurgical: false,
  site: '',
  smoking: '',
  treatment: '',
  treatmentPlan: '',
  type: '',
  viability: 'unknown',
  visitId: '',
  weight: 0,
  width: 1,
}

function formatNumber(number)
{
    number = number.toFixed(2) + ''
    let x = number.split('.')
    let x1 = x[0]
    let x2 = x.length > 1 ? '.' + x[1] : ''
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, `$1,$2`)
    }
    return x1 + x2
}

class authorize extends Component {
  constructor(props) {
    super(props)

    this.state = {
      patient: {
        firstName: 'Bami',
        gender: 'Ms.',
        ICD10Codes: ICD10CODES,
        medicalAid: 'Fedhealth',
        memberNo: '111',
        mobile: '082',
        patientName: 'Goreng',
        referringDrNo: '111',
        referringDoctor: 'Dr. Who',
        SAID: '111',
      },
      assessment: {},
      AuthButtOn: false,
      buttonType: '',
      createOpen: false,
      dateString: 'dateString',
      editOpen: false,
      error: false,
      fieldname: '',
      fieldvalue: null,
      filter: '',
      fullName: 'Ms. Bami Goreng',
      groupDetails: {},
      images: [],
      InvoiceButtOn: false,
      login: false,
      paLoading: false,
      printAuthOpen: false,
      printInvoiceOpen: false,
      printClicked: false,
      products: [],
      prodList: [],
      sbLoading: false,
      selectedRows: [],
      selectVisitOn: false,
      stockItems: [],
      supplRows1: [],
      totals: 0,
      treatmentDates: [],
      viLoading: true,
      viewOpen: false,
      visit: {},
      visitId: '',
      visitsMap: {},
      visits: [],
    }
  }

  fetchVisits = () => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    this.setState({ viLoading: true })
    axios
		.get(`/api/visits/group/${this.props.groupId}`)
		.then((response) => {
      var visitsMap = response.data.reduce((map,obj) => {
        const { id, ...rest } = obj;
        map[id] = rest
        return map;
      }, {});
			this.setState({
				visits: response.data,
				visitsMap: visitsMap,
				viLoading: false,
			})
		})
		.catch((error) => {
			if (error.response.status === 403) {
				this.setState({ login: true })
			}
			else {
				this.setState({ viLoading: false })
				console.log(error)
        }
    })
  }

  fetchProducts = () => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }

    this.setState({ productLoading: true })
    axios
      .get(`/api/products/group/${this.props.groupId}`)
      .then((response) => {
        this.setState({
          products: response.data
        })
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          this.setState({ productLoading: false })
          console.log(error)
        }
      })
  }


  componentDidMount = () => {
    const r = refreshToken()
    if (r === 403) {
      this.setState({login:true})
    }
    else {
      this.fetchVisits()
      this.fetchProducts() 
    }
  }

  getPatient = (visitId) => {

    let masterVisit = {}

    for (const visit of this.state.visits) {
      if (visitId === visit.id) {
        masterVisit = visit
        break
      }
    }
    const SAID = masterVisit.SAID

    let dateString = '';
    for (const visitId of this.state.selectedRows) {
      for (const visit of this.state.visits) {
        if (visit.id === visitId) {
          dateString = dateString + visit.date +  ', '
        }   
      }
    }
    dateString = dateString.substring(0,dateString.length - 2);
    this.setState({dateString})

    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    this.setState({paLoading:true})
    axios
      .get(`/api/patients/${SAID}`)
      .then((response) => {
        const fullName =
          response.data[0].gender + ' ' +
          response.data[0].firstName + ' ' +
          response.data[0].patientName
        let ICD10Codes = ICD10CODES
        if ( !(response.data[0].ICD10Codes === '' ||  response.data[0].ICD10Codes === undefined) )
          ICD10Codes = response.data[0].ICD10Codes   
        const supplRows1 = (masterVisit.home === '1') ? 
          [
            this.createData('Treatment Dates:', dateString),
            this.createData('Treatment Codes:',  TREATCODE88100),
            this.createData(' ',  TREATCODE88002),
            this.createData(' ',  TREATCODE88041),
            this.createData('ICD10 Codes:', ICD10Codes),
          ] :
          [
            this.createData('Treatment Dates:', dateString),
            this.createData('Treatment Codes:',  TREATCODE88002),
            this.createData(' ',  TREATCODE88041),
            this.createData('ICD10 Codes:', ICD10Codes),
          ]
        const patient = {...response.data[0]}
        patient.ICD10Codes = ICD10Codes
        this.getStockItems(ICD10Codes)
        this.setState({
          patient: patient,
          paLoading: false,
          fullName: fullName,
          supplRows1 : supplRows1
        })
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          this.setState({ paLoading: false })
          console.log(error)
        }
      })
  }


  fetchImages = (visitId) => {
    this.setState({ phLoading: true })
    let images = [...this.state.images]
    axios
      .get(`/api/photoBatch/${visitId}`)
      .then((response) => {
        if (response.data.length > 0) {
          for (const image of response.data)
            images.push(image)
        }
        this.setState({images})
        this.setState({phLoading: false})
      })
      .catch((error) => {
          if (error.response.status === 403) {
            this.setState({ login: true })
          }
          else {
            this.setState({ phLoading: false })
            console.log(error)
          }
      })
  }

  getStockItems = (ICD10Codes) => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }

    this.setState({prodList:[]})
    this.setState({sbLoading:true})
    let totals = 0
    let prodList = [...this.state.prodList]
    let x = this.state.selectedRows.length // countdown to remove circularProgress element
    for (const row of this.state.selectedRows) {
      x -= 1
      const visitId = row
      const vDate = this.state.visitsMap[visitId].date
      axios  
        .get(`/api/stockBatch/${visitId}`)
        .then((response) => {
          for (const rdata of response.data) {
            // get product details
            if (rdata.quantity === undefined)
              rdata.quantity = 1 
            for (const prod of this.state.products) {
              if (rdata.product === prod.title) {
                let sp = Number(`${prod.sellingPrice}`) * rdata.quantity
                let id = Math.random().toString(36).substr(2, 16)
                let tariffCode = TARIFFCODE
                let nappiCode = prod.nappiCode
                if (nappiCode.substr(0,2) === '88') {
                    tariffCode = nappiCode
                    nappiCode = ''
                }
                const newProd = {
                  id: id,
                  visitId: visitId,
                  date: vDate,
                  tariffCode: tariffCode,
                  nappiCode: `${nappiCode}`,
                  title: `${prod.title}`,
                  ICD10Codes: ICD10Codes,
                  quantity: rdata.quantity,
                  amount: formatNumber(sp),
                  amt: sp
                  }
                totals += sp
                prodList = [...prodList, newProd]
                break;
              }
            }
          }
          this.setState({
            prodList,
            totals,
            sbLoading : (x>0)
          })
        })
      .catch((error) => {
          if (error.response.status === 403) {
            this.setState({ login: true })
          }
          else {
            this.setState({ phLoading: false })
            console.log(error)
          }
      })    
    }
  }

  getAssessment = (visitId) => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }

    this.setState({paLoading:true})
    axios
      .get(`/api/assessment/${visitId}`)
      .then((response) => {
        let assessment = assessment0
        if (response.data.length > 0)
          assessment = response.data[0]
        this.setState({
          assessment: assessment,
          paLoading: false,
        })
      })
      .catch((error) => {
          if (error.response.status === 403) {
            this.setState({ login: true })
          }
          else {
            this.setState({ paLoading: false })
            console.log(error)
          }
      })
  }

  getGroup = () => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    this.setState({paLoading:true})
    axios
      .get(`/api/group/${this.props.groupId}`)
      .then((response) => {
        this.setState({
          groupDetails: response.data,
          paLoading: false,
        })
      })
      .catch((error) => {
          if (error.response.status === 403) {
            this.setState({ login: true })
          }
          else {
            this.setState({ paLoading: false })
            console.log(error)
          }
      })
  }


  handlePrint = (event) => {
    this.setState({printClicked:true})
  }

  createData = (key, value) => {
    return { key, value }
  }

  handleRemoveImage = (imageId) => {
    const images = this.state.images.filter(m => m.id !== imageId)
    this.setState({ images })
  }

  handleTotal = (event) => {
    const prodList = this.state.prodList
    prodList[`${event.target.name}`] = event.target.value
    this.setState({ prodList })
    let totals = 0
    for (const pr of prodList) {
      totals = pr.quantity * pr.amount
    }
    this.setState({totals})
  }

  OpenPrintView = (mode) => {
    const visitId = this.state.selectedRows[0]
										   
    switch (mode) {
      case AUTHORIZE:
        this.getGroup()
        this.getPatient(visitId)
        this.getAssessment(visitId)
        this.fetchImages(visitId)
        this.setState({ printAuthOpen: true })
        break;
      case INVOICE:
        this.getGroup()
        this.getPatient(visitId)
        this.setState({ printInvoiceOpen: true })
        break;
      default:
        this.setState({ 
          AuthButtOn: false,
          InvoiceButtOn: false,
          printAuthOpen: false, 
          printInvoiceOpen: false, 
         })
    }
  }

  render() {
    if (this.state.login === true) {
      return <Redirect
        to={{ pathname: "/login" }}
      />
    }
    const { classes } = this.props
    const { visitsMap, dateString, fullName, groupDetails, paLoading, 
      patient, printAuthOpen, printInvoiceOpen, sbLoading, viLoading } = this.state


    const subTitle =
      this.state.error === false ? (
        <div className={classes.subTitle}>SELECT CARE PLAN TO AUTHORIZE</div>
      ) : (
        <div className={classes.error}>Wrong Input!</div>
      )
    
    const handleVisitSelectionChange = (e) => {
      let thorough = true

      // List of selected visit IDs should relate to a single customer

      for (var x = 1; x < e.length; x++) {
        if (visitsMap[e[0]].SAID !== visitsMap[e[x]].SAID) {
          thorough = false
          break
        }
      }
      // 'thorough' in this context: one or more visit(s) of a single patient selected
      this.setState({ InvoiceButtOn: (thorough && e.length > 0) })
      this.setState({ AuthButtOn: (e.length === 1) })
      this.setState({ selectedRows: e })
    }
  
    const ClickToAuthVisitButton = this.state.AuthButtOn ? (
      <IconButton
        className={classes.selectButton}
        color="primary"
        aria-label="Select visit"
        onClick={() => this.OpenPrintView(AUTHORIZE)}
        size="large">
        <LaunchIcon style={{ fontSize: 60 }} />
      </IconButton>
    ) : ( '' )

    const ClickToReceiptButton = this.state.InvoiceButtOn ? (
      <IconButton
        className={classes.selectButton}
        color="primary"
        aria-label="Select visit"
        onClick={() => this.OpenPrintView(INVOICE)}
        size="large">
        <ReceiptLongIcon style={{ fontSize: 60 }} />
      </IconButton>
    ) : ( '' )

    const handleClose = (event) => {
      this.setState({
        printAuthOpen: false,
        printInvoiceOpen: false,
        editOpen: false,
        images:[],
        prodList:[]
      })
    }

    const visitColumns = [
      {
        field: 'date',
        headerName: 'Date',
        width: 120,
        editable: false,
      },
      {
        field: 'patientName',
        headerName: 'Surname',
        width: 160,
        editable: false,
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        width: 160,
        editable: false,
      },
      {
        field: 'SAID',
        headerName: 'ID/Passport',
        width: 160,
        editable: false,
      },
      {
        field: 'medicalAid',
        headerName: 'Medical Aid',
        width: 160,
        editable: false,
      },
      {
        field: 'memberNo',
        headerName: 'Membership No.',
        width: 150,
        editable: false,
      },
    ]

    const prodListColumns = [
      {
        field: 'tariffCode',
        headerName: 'Tariff Code',
        width: 100,
        editable: false,
      },
      {
        field: 'nappiCode',
        headerName: 'Nappi Code',
        width: 115,
        editable: false,
      },
      {
        field: 'title',
        headerName: 'Materials',
        width: 450,
        editable: false,
      },
      {
        field: 'quantity',
        headerName: 'Qty',
        width: 100,
        editable: true,
      },
      {
        field: 'amount',
        headerName: 'Cost, excl. VAT',
        width: 124,
        editable: true,
      },
    ]

    const prodListColumnsInv = [
      {
        field: 'date',
        headerName: 'Date of Service',
        width: 110,
        editable: true,
      },
      {
        field: 'tariffCode',
        headerName: 'Tariff Code',
        width: 86,
        editable: false,
      },
      {
        field: 'nappiCode',
        headerName: 'Nappi Code',
        width: 106,
        editable: true,
      },
      {
        field: 'title',
        fontSize: 16,
        headerName: 'Materials',
        width: 370,
        editable: true,
      },
      {
        field: 'ICD10Codes',
        headerName: 'ICD10 Codes',
        width: 154,
        default: '',
        editable: true,
      },
      {
        field: 'quantity',
        headerName: 'Qty',
        width: 60,
        editable: true,
      },
      {
        field: 'amount',
        headerName: 'Cost (vat incl.)',
        width: 100,
        editable: true,
      },
    ]

    let {depth, depth2, depth3, length, length2, length3, width, width2, width3} = this.state.assessment
    let size1 = ''
    if (depth !== undefined && depth + length + width !== '') {
      depth = (depth === '') ? depth : ' x ' + depth
      width = (width === '') ? width : ' x ' + width 
      size1 = length + width + depth
    }


    let size2 = ''
    if (depth2 !== undefined && depth2 + length2 + width2 !== '') {
      depth2 = (depth2 === '') ? depth2 : ' x ' + depth2
      width2 = (width2 === '') ? width2 : ' x ' + width2 
      size2 = length2 + width2 + depth2
    }

    let size3 = ''
    if (depth3 !== undefined && depth3 + length3 + width3 !== '') {
      depth3 = (depth3 === '') ? depth3 : ' x ' + depth3
      width3 = (width3 === '') ? width3 : ' x ' + width3 
      size3 = length3 + width3 + depth3
    }

    let size = (size1 !== '') ? size1 : ''
    size = (size2 !== '') ? size + ' / ' + size2 : size
    size = (size3 !== '') ? size + ' / ' + size3 : size
     
      const postsurgical = (this.state.assessment.postsurgical ? 'Yes' : 'No')
      const infection = (this.state.assessment.infection ? 'Yes' : 'No')
      const inflammation = (this.state.assessment.inflammation ? 'Yes' : 'No')
      const supplRows2 = [
        this.createData('Wound History:', this.state.assessment.medInfo),
        this.createData('Postsurgical:', postsurgical),
        this.createData('Infection:', infection),
        this.createData('Inflammation:', inflammation),
        this.createData('Site:', this.state.assessment.site),
        this.createData('Wound Size (cm):', size),
        this.createData('Moisture Balance:', this.state.assessment.balance),
        this.createData('Epithelial Margin:', this.state.assessment.margin),
        this.createData('Tissue Viability:', this.state.assessment.viability),
        this.createData('Texas Classification:', this.state.assessment.texas),
        this.createData('C.E.A.P:', this.state.assessment.ceap),
        this.createData('T.B.S.A:', this.state.assessment.tbsa),
      ]

    const fileName = patient.patientName + "_" + patient.firstName + "_" + dateString

    // const ReactToPrintStub = (
    //     <ReactToPrint
    //       content={() => this.componentRef}
    //       copyStyles="true"
    //       documentTitle={fileName}
    //       // onAfterPrint={handleClose} // if active, it closes the print view
    //       trigger={() => {
    //         // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    //         // to the root node of the returned component as it will be overwritten.
    //         return <a className={classes.a} href="#">
    //           Print PDF
    //         </a>
    //       }}
    //     />
    // )

    const ReactToPrintStub = (
      <ReactToPrint content={() => this.componentRef}
        documentTitle={fileName}
        removeAfterPrint='true'
      >
        <PrintContextConsumer>
          {({ handlePrint }) => (
            <button 
              className={classes.butto}
              onClick={handlePrint}
            >
              Print PDF
            </button>
          )}
        </PrintContextConsumer>
      </ReactToPrint>
    )


    let authImages = [];
    if (this.state.images.length) {
      authImages = 
        <ImageList 
          rowHeight={600} 
          className={classes.imageList} 
          cols={2}
        >
          {this.state.images.map((image) => (
            <ImageListItem key={image.id} cols={image.cols || 1}>
              <img src={image.imageUrl} alt={image.title} />
              <ImageListItemBar
                title={image.title}
                actionIcon={
                  <IconButton
                    color="action"
                    // sx={{ color: 'rgba(255, 255, 255, 0.25)' }}
                    onClick={() => this.handleRemoveImage(image.id)}                    
                  >
                    <CloseIcon />
                  </IconButton>
                }      
              />
            </ImageListItem>
          ))}
        </ImageList>
    }

    const ImageContainer = 
    (
      <div className={classes.imageContainer} >
        {authImages}
    </div>
    )

    const CompanyDetailsHeader = 
    (
      <div style={{ width: '20%' }}>
        <center>
          <Typography variant="h3" noWrap>
            <b>{groupDetails.hdetail1} - {groupDetails.hdetail2}</b>
          </Typography>
          <Typography variant="h6" noWrap>
            {groupDetails.hdetail11} / {groupDetails.hdetail12}
          </Typography>
          < hr/>
          <Box className={classes.letterHead}>
            <p>{groupDetails.hdetail5}</p>
            <p>{groupDetails.hdetail7}</p> 
          </Box>
        </center>
      </div>
    )

    const AuthorizeRequestHeader = 
    (
      <div style={{marginLeft:'120px', fontSize: '11pt', padding: "6px 0px 6px 16px"}}>
        <h1>Authorization Request for wound care</h1>
        <h3>to: {patient.medicalAid}</h3>
        <h3>Att: Pre-Authorization</h3>
        <h2>
          Please note wound care was required for the following
          patient:
        </h2>
        <h3>Name: {fullName}</h3>
        <h3>Contact Number: {patient.mobile}</h3>
        <h3>Member Number: {patient.memberNo}</h3>
        <h3>ID: {patient.SAID}</h3>
        <h3>
          Referring Doc:{' '}{patient.referringDoctor}{'  '}
          {patient.referringDrNo}
        </h3>
      </div>
    )

    const InvoiceHeader = 
    (
      <div  contentEditable={true} suppressContentEditableWarning={true}>
        <article className={classes.article0}>
           <Grid container item xs={12} spacing={0} >
            <Grid className={classes.upAndLeft} item xs={10}>
              <Stack spacing={1}>
                <h3>{groupDetails.hdetail1}</h3>
                <h3>{groupDetails.hdetail2}</h3>
                <b>{groupDetails.hdetail3}</b>
              </Stack>
            </Grid>
            <Grid className={classes.upAndRight} item xs={2}>
              <Logo/>
            </Grid>
          </Grid>
        </article>
        <article className={classes.article0}>
          <Grid container item xs={12} spacing={0} >
            <Grid className={classes.upAndRight} item xs={12}>
              {groupDetails.hdetail4}
            </Grid>
            <Grid className={classes.upAndLeft} item xs={8}>
             <b>{groupDetails.hdetail5}</b>
            </Grid>
            <Grid className={classes.upAndRight} item xs={4}>
              {groupDetails.hdetail6}
            </Grid>
            <Grid className={classes.upAndLeft} item xs={8}>
             <b>{groupDetails.hdetail7}</b>
            </Grid>
            <Grid className={classes.upAndRight} item xs={4}>
              {groupDetails.hdetail8}
            </Grid>
            <Grid className={classes.upAndLeft} item xs={8}>
             <b>{groupDetails.hdetail9}</b>
            </Grid>
            <Grid className={classes.upAndRight} item xs={4}>
              {groupDetails.hdetail10}
            </Grid>
          </Grid>
        </article>
        <article className={classes.article2}>          
          <Grid container item xs={12} spacing={0}>
            <Grid className={classes.upAndBold} item xs={4}>
              Tax Invoice
            </Grid>
            <Grid className={classes.upAndRight} item xs={8}>
              Invoice Date: {dateString}
            </Grid>
            <Grid className={classes.upAndBold} item xs={9}>
              {patient.firstName} {patient.patientName}
            </Grid>
            <Grid className={classes.upAndRight} item xs={3}>
              Medical Aid: {patient.medicalAid}
            </Grid>
            <Grid className={classes.upAndRight} item xs={12} editable>
              Member No: {patient.memberNo}
            </Grid>
            <Grid className={classes.upAndRight} item xs={12} editable>
              Authorization No: {patient.authNo}
            </Grid>
          </Grid>
        </article>
        <article className={classes.article1}>
          <br />
          <br />
          <span>
            Patient: {patient.firstName} {patient.patientName}, 
            d.o.b: {patient.dateOfBirth}, Dep Code: {patient.dependentCode}
          </span>
          <Divider style={{ background: 'black' }} variant="left"  />
          <span className={classes.finePrint}> 
            Treating Provider: <b>{groupDetails.hdetail1}</b>, {groupDetails.hdetail11}, Date of Service: {dateString}<br/>
          </span>
          <span className={classes.finePrint}>
            ICD10 Codes: {patient.ICD10Codes}
          </span>
          <br />
          <br />
        </article>
      </div>
    )

    const InvoiceBottom = (
      <article className={classes.article3}> 
        <Grid className={classes.finePrint2} container item xs={12} spacing={0}>
          <Grid item xs={2}>
            Bank Details
          </Grid>
          <Grid item xs={10}>
            <span>
            <b>{groupDetails.bdetail1}, {groupDetails.bdetail2},</b> Branch <b>{groupDetails.bdetail5}, </b>
               Account no: <b>{groupDetails.bdetail3},</b> Account Type: <b>{groupDetails.bdetail4}</b>
            </span>
          </Grid>
          <Divider/>
          <Grid item xs={2}>
            Company Info
          </Grid>
          <Grid item xs={10}>
            <b>{groupDetails.cdetail1}</b>
          </Grid>
          <Divider/>
          <Grid item xs={2}>
            EFT Info
          </Grid>
          <Grid item xs={10}>
              {groupDetails.edetail1} <b>{patient.firstName} {patient.patientName}</b>{groupDetails.edetail2} {groupDetails.edetail3}
          </Grid>
        </Grid>
      </article>
    )

    const TreatmentCodes =
    (
      <div>
      <Table className={classes.table} size="small">
        <TableHead>
          </TableHead>
        <TableBody>
          {this.state.supplRows1.map((row) => (
          <TableRow key={row.key}>
            <TableCell
              style={{width: "130pt"}} 
              component="th" 
              scope="row"
            >
              {row.key}
            </TableCell>
            <TableCell contentEditable={true} suppressContentEditableWarning={true}>
              {row.value}
            </TableCell>
          </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
    )

    const ListOfProductsUsedAuth =
    (
      <div style={{marginLeft:'60px'}}>
        <div style={{ width: 1000 }}>
          <DataGrid
            className={classes.prodGrid}
            rows={this.state.prodList}
            columns={prodListColumns}
            autoHeight='true'
            hideFooter='true'
            showCellRightBorder='true'
            showColumnRightBorder='true'
          />
        </div>
      </div>
    )

    let rh = Math.trunc(800 / this.state.prodList.length)
    let rheight = rh > 46 ? 46 : rh -1
    const iheight = 790
    const ListOfProductsUsedInv =
    (
      <div style={{marginLeft:'80px'}}>
        <div style={{ height: iheight }}>
          <DataGrid
            // getRowHeight={() => '46px'}
            className={classes.prodGridInv}
            rows={this.state.prodList}
            columns={prodListColumnsInv}
            rowHeight={rheight}
            autoHeight='true'
            hideFooter='true'
            showCellRightBorder='true'
            showColumnRightBorder='true'
          />
        </div>
      </div>
    )
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'ZAR',
    })

    const SumTotal = this.state.totals > 0 ?
    (      
      <article 
        className={classes.article4}
      > 
          <Grid container item xs={12} spacing={0} contentEditable={true} suppressContentEditableWarning={true}>
            <Grid className={classes.upAndBold} item xs={9} >
              Totals
            </Grid>
            
            <Grid className={classes.upAndUnderline} item xs={3} >
              {formatter.format(this.state.totals)}
            </Grid>
            {/* <b>
            <TextField 
              id="standard-basic" 
              label="" 
              defaultValue={formatter.format(this.state.totals)}
              variant="standard" 
            />
            </b> */}
          </Grid>
      </article>
    ) : ''

    const Supplements = 
    (
      <div>
        <Table className={classes.table} size="small">
          <TableHead>
            </TableHead>
          <TableBody>
            {supplRows2.map((row) => (
            <TableRow key={row.key}>
              <TableCell
                style={{width: "130pt"}} 
                component="th" 
                scope="row"
              >
                {row.key}
              </TableCell>
              <TableCell contentEditable={true} suppressContentEditableWarning={true}>
                {row.value}
              </TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )

    const AuthAppBar =
    (
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.poptitle}>
            {'Print View'}
          </Typography>
          {ReactToPrintStub}
        </Toolbar>
      </AppBar>
    )

    const AuthDialog = 
    (
      <Dialog
        fullScreen
        open={printAuthOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {AuthAppBar}
        <div
            style={{ height: 1500, width: '500%' }}
            ref={(el) => (this.componentRef = el)}
        >
          <p/>
          {CompanyDetailsHeader}
          {( sbLoading ) && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
          {AuthorizeRequestHeader}
          {TreatmentCodes}
          {ListOfProductsUsedAuth}
          {Supplements}
          {ImageContainer}
        </div>
      </Dialog>
    )

    const InvoiceDialog = 
    (
      <Dialog
        fullScreen
        open={printInvoiceOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {AuthAppBar}
        <div
            style={{ height: 1200, width: '500%' }}
            ref={(el) => (this.componentRef = el)}
        >
          <p/>
          {InvoiceHeader}
          {( sbLoading ) && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
          {!(paLoading) && ListOfProductsUsedInv}
          {SumTotal}
          {InvoiceBottom}
        </div>
      </Dialog>
    )

    const AuthEntryGrid =
    (
      <div style={{ width: '740pt', height: '480pt' }}>
        <DataGrid
          className={classes.visitGrid}
          rows={this.state.visits}
          columns={visitColumns}
          isRowSelectable={() => true}
          checkboxSelection
          onSelectionModelChange={handleVisitSelectionChange}
          // onRowSelectionModelChange={handleVisitSelectionChange} needed with MUI/x-data-grid but not working
          // disableSelectionOnClick
          // onRowEnter={fetchKey}
          // onRowDoubleClick={OpenAuthPrintView}
        />
      </div>
    )

    const TabTitle = (
      <Grid container item xs={12} spacing={0}>
        <Grid item xs={6}>
          {subTitle}
        </Grid>
        <Grid item xs={2}>
          {ClickToAuthVisitButton}
        </Grid>
        <Grid item xs={1}>
          {ClickToReceiptButton}
        </Grid>
      </Grid>
    ) 

    if ( viLoading ) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {( viLoading ) && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      )
    } else {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {TabTitle}
          {/* ### DataGrid to display visits to be authorized */}
          {AuthEntryGrid}
          {AuthDialog}
          {InvoiceDialog}
        </main>
      );
    }
  }
}

export default withStyles(styles)(authorize)
