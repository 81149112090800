import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { refreshToken } from './refresh.js'

import { withStyles } from '@mui/styles';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'

import { DataGrid } from '@mui/x-data-grid'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import axios from 'axios'

import { grey, red } from '@mui/material/colors'

const styles = (theme) => ({
  addButton: {
    // position: 'fixed',
    bottom: 0,
    right: 0,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  appBar: {
    position: 'sticky',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    color: theme.palette.grey[200],
    ["@media (max-width:700px)"]: {
      maxWidth: '414px'
    },
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  dialogStyle: {
    ["@media (max-width:700px)"]: {
      maxWidth: '414px'
    },
  },
  deleteButton: {
    // position: 'fixed',
    bottom: 0,
    right: 0,
  },
  error: {
    fontSize: '20px',
    color: red[400],
  },
  form: {
    backgroundColor:'#fafafa',
    width: '93%',
    marginLeft: 13,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: 14,
  },
  formTextField: {
    backgroundColor:'#ffffff',
    fontSize: 14,
  },
  input: {
    marginBottom: 20,
  },
  pos: {
    marginBottom: 12,
  },
  productGrid: {
    // Wound Solutions:
    // background: 'linear-gradient(45deg, #C0180CD0 30%, #FBB9C4D0 90%)',
    background: 'linear-gradient(45deg, #cb5921D0 30%, #a99787D0 90%)',
    // ConsultUs:
    // background: 'linear-gradient(45deg, #FF8E53D0 30%, #FE6B8BD0 90%)',
  },
  root: {
    minWidth: 470,
  },
  submitButton: {
    display: 'block',
    textAlign: 'center',
    position: 'absolute',
    right: 10,
  },
  subTitle: {
    fontSize: '22px',
    width: 188,
    color: grey[800],
    top: 20,
    bottom: 20,
    // ConsultUs:
    // background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
    // Wound Solutions:
    // background: 'linear-gradient(45deg, #C0180CD0 30%, #FBB9C4D0 90%)', // nice Grenadilla
    background: 'linear-gradient(45deg, #cb5921D0 30%, #a99787D0 90%)',
    borderRadius: '8px',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: theme.mixins.toolbar,
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  viewRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
})

const product0 = {
  id: Math.random(),
  avgCost: 0,
  category: '1',
  default: false,
  nappiCode: '',
  open: true,
  orderThreshold: 0,
  sellingPrice: 0,
  title: '',
  volume: 0,
}

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
))

class product extends Component {
  constructor(props) {
    super(props)

    this.state = {
      buttonType: '',
      deleteInfo: 0,
      deleteModeOn: false,
      displaySubMenuButtons: 0,
      error: false,
      errors: {},
      fieldname: '',
      fieldvalue: null,
      filter: '',
	  login: false,
      mouseX: null,
      mouseY: null,
      open: false,
      product: {},
      products: [],
      selectedRows: null,
      uiLoading: true,
      viewOpen: false,
    }

  }

  fetchProducts = () => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    axios
      .get(`/api/products/group/${this.props.groupId}`)
      .then((response) => {
        this.setState({
          products: response.data,
          uiLoading: false,
        })
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({login: true})
        }
        else {
          this.setState({ uiLoading: false })
          console.log(error)
        }
      })
  }


  componentDidMount = () => {
    const r = refreshToken()
    if (r === 403) {
      this.setState({login:true})
    }
    else {
      this.fetchProducts() 
    }
  }

  render() {
    if (this.state.login === true) {
      return <Redirect
        to={{ pathname: "/login" }}
      />
    }

    const { classes } = this.props
    const { open, errors, product } = this.state

    const handleClickToCreate = () => {
      this.setState({
        buttonType: 'Create',
        deleteInfo:0,
        displaySubMenuButtons:0,
        errors: {},
        open: true,
        product: product0,
      })
    }

    const handleClickToEdit = () => {
      this.setState({
        errors: {},
        open: true,
        mouseX: null,
        mouseY: null,
        buttonType: 'Edit'
      })
    }

    const handleClose = (event) => {
      this.setState({ 
        open: false,
        mouseX: null,
        mouseY: null,
       })
    }

    const handleDoubleClick = (event) => {
      const mouseX = this.state.mouseX ? null : event.clientX - 2
      const mouseY = this.state.mouseY ? null : event.clientY - 4
      this.setState({
        displaySubMenuButtons: 0,
        mouseX, 
        mouseY
      })
    }

    const handleSingleClick = (event) => {
      this.setState({displaySubMenuButtons:1})
    }

    // const handleRightClick = (event) => {
    //   event.preventDefault()
    //   this.setState({
    //     mouseX: event.clientX - 2,
    //     mouseY: event.clientY - 4,
    //   })
    // }

    const handleCMenuClose = () => {
      this.setState({
        displaySubMenuButtons:0,
        mouseX: null,
        mouseY: null,
      })
    }

    const fetchKey = (event) => {
      this.setState({
        deleteInfo:0,
        product: event.row,
      })
    }

    const handleChangeProductDetail = (event) => {
      let val = event.target.value
      switch (event.target.name) {
        case 'avgCost' :
        case 'orderThreshold' :
        case 'sellingPrice' :
        case 'volume' :
        case 'nappiCode' :
          val = val.trim()     
          if (isNaN(val)) {
            break;
          }
        default:
          const {...userproduct} = product
          userproduct[`${event.target.name}`] = val
          this.setState({ product:userproduct })
      }
    }

    const handleCreateOrEditProduct = (event) => {
      event.preventDefault()
      let {id, nappiCode, title, ...userproduct} = product
      userproduct.nappiCode = nappiCode.trim()
      userproduct.title = title.trim()
      userproduct.groupId = this.props.groupId

      let errors = {}
      errors.avgCost = isNaN(userproduct.avgCost) || (userproduct.avgCost === '')
      errors.category = (!(userproduct.category === '1' ||  userproduct.category === '2'))
      errors.nappiCode = isNaN(userproduct.nappiCode) || (userproduct.nappiCode === '')
      errors.orderThreshold = isNaN(userproduct.orderThreshold) || (userproduct.orderThreshold === '')
      errors.sellingPrice = isNaN(userproduct.sellingPrice) || (userproduct.sellingPrice === '')
      errors.title = (userproduct.title === '')
      errors.volume = isNaN(userproduct.volume) || (userproduct.volume === '')

      let er = false
      for (const err in errors) {
        er = er || errors[err]
      }
      if (er === true) {
        this.setState({errors})
        return
      }

      let options = {}
      if (this.state.buttonType === 'Edit') {
        options = {
          url: `/api/product/${id}`,
          method: 'put',
          data: userproduct,
        }
      }
      else {
        options = {
          url: '/api/product',
          method: 'post',
          data: userproduct,
        }
      }
      const authToken = localStorage.getItem('AuthToken')
      axios.defaults.headers.common = { Authorization: `${authToken}` }

      this.setState({ uiLoading: true })
      axios(options)
        .then((response) => {
          let products = []
          if (options.method === 'post') {
            this.setState({
              product: product0,  // just wiping the display
              open: false
            }) 
            products = [...this.state.products]
            products.unshift(response.data)
          }
					else {  // if (options.method === 'put') {
            this.setState({open:false})
            Object.assign(userproduct, {id: `${id}`})
            products = this.state.products.map((product) => {
              return product.id === userproduct.id ? userproduct : product
            });
          }
          this.setState({ 
            uiLoading: false, 
            products 
          })
          // window.location.reload(); # lass ich hier nur stehen, damit ich's irgendwann finde wenn ich es brauch'
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.setState({login: true})
          }
          else {
            this.setState({
              open: true,
              errors: error.response.data,
              uiLoading: false,
            })
            console.log(error)
          }
        })
    }

    const handleClickToDelete = () => {
      const authToken = localStorage.getItem('AuthToken')
      axios.defaults.headers.common = { Authorization: `${authToken}` }

      this.setState({ uiLoading: true })
      axios
          .delete(`/api/product/${product.id}`)
          .then(() => {
            const products = this.state.products.filter((x) => x.id !== product.id)
            this.setState({
              deleteInfo:1,
              displaySubMenuButtons:0,
              mouseX:null,
              mouseY:null,
              open:false,
              products,
              uiLoading: false, 
            })
          })
          .catch((error) => {
            if (error.response.status === 403) {
              this.setState({login: true})
            }
            else {
              this.setState({ uiLoading: false })
              console.log(error)
            }
          })
    }
  
  

    // Das nehme ich erstmal raus:
    // const handleSelectionChange = (e) => {
    //       this.setState({deleteModeOn:(e.length > 0)});
    //       this.setState({selectedRows:e})
    // }

    const productColumns = [
      {
        field: 'title',
        headerName: 'Title',
        width: 530,
        editable: false,
      },
      {
        field: 'nappiCode',
        headerName: 'Nappi Code',
        width: 150,
        editable: false,
      },
      {
        field: 'default',
        headerName: 'Default',
        width: 100,
        editable: false,
      },
    ]

    const Error =
      this.state.error === false ? (
        <div className={classes.subTitle}>&nbsp;PRODUCTS LIST</div>
      ) : (
        <div className={classes.error}>PRODUCTS LIST - Wrong Input!</div>
      )

      const addButton = (
        <IconButton
          className={classes.floatingButton}
          color="primary"
          aria-label="Add Product"
          onClick={handleClickToCreate}
          size="large"
        >
          <AddCircleIcon style={{ fontSize: 60 }} />
        </IconButton>
      )
  
      const editButton = (
        <IconButton
              className={classes.floatingButton}
              color="primary"
              aria-label="Product Details"
              onClick={handleClickToEdit}
              size="large"
        >
          <EditIcon style={{ fontSize: 60 }} />
        </IconButton>
      )
  
      const deleteButton = (
        <IconButton
          className={classes.floatingButton}
          color="primary"
          aria-label="Delete Product"
          onClick={handleClickToDelete}
          size="large"
        >
          <DeleteIcon style={{ fontSize: 60 }} />
        </IconButton>
      ) 
  
      const deleteInfo = this.state.deleteInfo ? (
        <Button
          className={classes.floatingButton}
          color="primary"
        >
          Delete Successful  
        </Button>
      ) : ''
  
      const subMenuButtons = this.state.displaySubMenuButtons ? (
        <div>
          {addButton}
          {editButton}
          {deleteButton}
          {deleteInfo}
        </div>
      ) : (
        <div>
          {addButton}
          {deleteInfo}
      </div>
      )
  
    const contextMenu = (
      <Menu
        className={classes.contextMenu}
        keepMounted
        open={this.state.mouseY !== null}
        onClose={handleCMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          this.state.mouseY !== null && this.state.mouseX !== null
            ? { top: this.state.mouseY, left: this.state.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleClickToEdit}>Product Details</MenuItem>
        <MenuItem >---------------</MenuItem>
        <MenuItem onClick={handleClickToDelete}>Delete Product</MenuItem>
      </Menu>
    )


    if (this.state.uiLoading === true) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      )
    } else {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div>
            {Error}
            {subMenuButtons}
          </div>
          <div
//            onContextMenu={handleRightClick}
            onClick={handleSingleClick}
            onDoubleClick={handleDoubleClick}
            style={{ cursor: 'context-menu' }}
          >
            {contextMenu}
            <div style={{ width: '530pt', height: '480pt' }}>
              <DataGrid
                className={classes.productGrid}
                rows={this.state.products}
                columns={productColumns}
                isRowSelectable={() => true}
                // onEditCellChange={handleEditCellChange}
                // onEditCellChangeCommitted={handleEditProduct}
                // checkboxSelection
                // onSelectionModelChange={handleSelectionChange}
                // disableSelectionOnClick
                onCellClick={fetchKey}
              />
            </div>
          </div>
           <Dialog
            fullScreen
            className={classes.dialogStyle}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
              <AppBar position="sticky">
                <Toolbar
                  variant="dense"
                >
                  <IconButton
                    autoFocus
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    {this.state.buttonType + ' Product'}
                  </Typography>
                  <Button
                    color="inherit"
                    onClick={handleCreateOrEditProduct}
                    className={classes.submitButton}
                  >
                    {this.state.buttonType === 'Edit' ? 'Save' : 'Submit'}
                  </Button>
                </Toolbar>
              </AppBar>

              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: '95%', backgroundColor:'white' }}
                      variant="outlined"
                      required
                      id="productTitle"
                      label="Product Title"
                      name="title"
                      autoComplete="title"
                      helperText={errors.title}
                      value={product.title}
                      error={errors.title ? true : false}
                      onChange={handleChangeProductDetail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.formTextField}
                      variant="outlined"
                      required
                      id="nappiCode"
                      label="Nappi Code"
                      name="nappiCode"
                      autoComplete="nappiCode"
                      helperText={errors.nappiCode}
                      value={product.nappiCode}
                      error={errors.nappiCode ? true : false}
                      onChange={handleChangeProductDetail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.formTextField}
                      variant="outlined"
                      required
                      id="sellingPrice"
                      label="Selling Price"
                      name="sellingPrice"
                      autoComplete="sellingPrice"
                      helperText={errors.sellingPrice}
                      value={product.sellingPrice}
                      error={errors.sellingPrice ? true : false}
                      onChange={handleChangeProductDetail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.formTextField}
                      variant="outlined"
                      required
                      id="avgCost"
                      label="Average Cost"
                      name="avgCost"
                      autoComplete="avgCost"
                      helperText={errors.avgCost}
                      value={product.avgCost}
                      error={errors.avgCost ? true : false}
                      onChange={handleChangeProductDetail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.formTextField}
                      variant="outlined"
                      required
                      id="volume"
                      label="Stock Volume"
                      name="volume"
                      autoComplete="volume"
                      helperText={errors.volume}
                      value={product.volume}
                      error={errors.volume ? true : false}
                      onChange={handleChangeProductDetail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.formTextField}
                      variant="outlined"
                      required
                      id="orderThreshold"
                      label="Order Threshold"
                      name="orderThreshold"
                      autoComplete="orderThreshold"
                      helperText={errors.orderThreshold}
                      value={product.orderThreshold}
                      error={errors.orderThreshold ? true : false}
                      onChange={handleChangeProductDetail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: '58%', backgroundColor:'white' }}
                      variant="outlined"
                      id="category"
                      label="Category"
                      name="category"
                      autoComplete="category"
                      helperText={errors.category}
                      defaultValue={this.state.category}
                      value={product.category}
                      error={errors.category ? true : false}
                      onChange={handleChangeProductDetail}
                      select
                    >
                      <MenuItem value="1">conv</MenuItem>
                      <MenuItem value="2">stoma</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: '58%', backgroundColor:'white' }}
                      variant="outlined"
                      id="default"
                      label="Default"
                      name="default"
                      autoComplete="default"
                      helperText={errors.default}
                      defaultValue={this.state.default}
                      value={product.default}
                      error={errors.default ? true : false}
                      onChange={handleChangeProductDetail}
                      select
                    >
                      <MenuItem value={true}>true</MenuItem>
                      <MenuItem value={false}>false</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </form>
          </Dialog>
        </main>
      )
    }
  }
}

export default withStyles(styles)(product)
