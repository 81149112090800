import React, { Component } from 'react'

import { withStyles } from '@mui/styles';
import {
	AppBar,
	Button,
	Card,
	CardActions,
	CardContent,
	CircularProgress,
	Dialog,
	Grid,
	Input,
	IconButton,
	Slide,
	TextField,
	Toolbar,
	Typography 
} from '@mui/material';
import { DialogTitle } from '@mui/material';
import { DialogContent } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { authMiddleWare } from '../util/auth';



const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	appBar: {
		position: 'relative'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	submitButton: {
		display: 'block',
		color: 'white',
		textAlign: 'center',
		position: 'absolute',
		right: 10
	},
	floatingButton: {
		position: 'fixed',
		bottom: 0,
		right: 0
	},
	form: {
		width: '98%',
		marginLeft: 13,
		marginTop: theme.spacing(3)
	},
  input: {
    marginBottom: 20
  },
	toolbar: theme.mixins.toolbar,
	root: {
		background: 'linear-gradient(90deg, #e6e1d790 10%, #e6e1d790 10%)',
		minWidth: 470
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)'
	},
	pos: {
		marginBottom: 12
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	dialogeStyle: {
		maxWidth: '50%'
	},
	viewRoot: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" {...props} ref={ref}  />;
});

class todo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			todos: '',
			title: '',
			body: '',
			todoId: '',
			errors: [],
			open: false,
			uiLoading: true,
			buttonType: '',
			viewOpen: false,
      filter: '',
		};

		this.deleteTodoHandler = this.deleteTodoHandler.bind(this);
		this.handleEditClickOpen = this.handleEditClickOpen.bind(this);
		this.handleViewOpen = this.handleViewOpen.bind(this);
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	componentDidMount = () => {
		authMiddleWare(this.props.history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get('/api/todos')
			.then((response) => {
				this.setState({
					todos: response.data,
					uiLoading: false
				});
			})
			.catch((error) => {
				if (error.response.status === 403) {
					this.props.history.push('/login');
				}
				console.log(error);
			});
	};

	deleteTodoHandler(data) {
		authMiddleWare(this.props.history)
		const authToken = localStorage.getItem('AuthToken')
		axios.defaults.headers.common = { Authorization: `${authToken}` }
		let todoId = data.todo.todoId
		this.setState({uiLoading:true})
		axios
			.delete(`/api/todo/${todoId}`)
			.then(() => {
				const todos = [...this.state.todos.filter(todo => todo.todoId !== todoId)]
				this.setState({uiLoading:false, todos})
			})
			.catch((error) => {
				if (error.response.status === 403) {
					this.props.history.push('/login');
				}
				this.setState({uiLoading:false})
				console.log(error)
			})
	}

	handleEditClickOpen(data) {
		this.setState({
			title: data.todo.title,
			body: data.todo.body,
			todoId: data.todo.todoId,
			buttonType: 'Edit',
			open: true
		});
	}

	handleViewOpen(data) {
		this.setState({
			title: data.todo.title,
			body: data.todo.body,
			viewOpen: true
		});
	}

	render() {
		// const DialogTitle = withStyles(styles)((props) => {
		// 	const { children, classes, onClose, ...other } = props;
		// 	return (
    //             <DialogTitle disableTypography className={classes.root} {...other}>
		// 			<Typography variant="h6">{children}</Typography>
		// 			{onClose ? (
		// 				<IconButton
    //                         aria-label="close"
    //                         className={classes.closeButton}
    //                         onClick={onClose}
    //                         size="large">
		// 					<CloseIcon />
		// 				</IconButton>
		// 			) : null}
		// 		</DialogTitle>
    //         );
		// });

		// const DialogContent = withStyles((theme) => ({
		// 	viewRoot: {
		// 		padding: theme.spacing(2)
		// 	}
		// }))(MuiDialogContent);

		dayjs.extend(relativeTime);
		const { classes } = this.props;
		const { open, errors, viewOpen } = this.state;

		const handleClickOpen = () => {
			this.setState({
				todoId: '',
				title: '',
				body: '',
				buttonType: '',
				open: true
			});
		};

		const handleSubmit = (event) => {
			authMiddleWare(this.props.history);
			event.preventDefault();
			const userTodo = {
				title: this.state.title,
				body: this.state.body
			};
			const todos = [...this.state.todos]
			let options = {};
			if (this.state.buttonType === 'Edit') {
				options = {
					url: `/api/todo/${this.state.todoId}`,
					method: 'put',
					data: userTodo
				};
			} else {
				options = {
					url: '/api/todo',
					method: 'post',
					data: userTodo
				};
			}
			const authToken = localStorage.getItem('AuthToken');
			axios.defaults.headers.common = { Authorization: `${authToken}` };
			axios(options)
				.then((response) => {
					if (options.method === 'post') {
						const {id, title, body, user} = response.data
						const todo =
							{ todoId : id,
								title: title,
								user: user,
								body: body
							}
        		todos.push(todo)
					}
					else  // if (options.method === 'put') {
						for (const todo of todos) {
							if (todo.todoId === this.state.todoId) {
								todo.title = userTodo.title
								todo.body = userTodo.body
								break
							}
						}
					this.setState({ open: false, todos });
				})
				.catch((error) => {
					if (error.response.status === 403) {
						this.props.history.push('/login');
					}
				  this.setState({ open: true, errors: error.response.data });
					console.log(error);
				});
		};

		const handleViewClose = () => {
			this.setState({ viewOpen: false });
		};

		const handleClose = (event) => {
			this.setState({ open: false });
		};

    const handleFilter = (event) => {
        this.setState({ filter:event.target.value});
    };

		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
                <main className={classes.content}>
					<div className={classes.toolbar} />

					<IconButton
                        className={classes.floatingButton}
                        color="primary"
                        aria-label="Add Message"
                        onClick={handleClickOpen}
                        size="large">
						<AddCircleIcon style={{ fontSize: 60 }} />
					</IconButton>

            <Input id="Todo-Filter" placeholder="FilterElement" variant="filled" onChange={handleFilter}/>

          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
						<AppBar position="static">
              <Toolbar variant="dense">
								<IconButton
									edge="start"
									color="inherit"
									onClick={handleClose}
									aria-label="close"
									size="large">
									<CloseIcon />
								</IconButton>
								<Typography variant="h6" className={classes.title}>
									{this.state.buttonType === 'Edit' ? 'Edit Message' : 'Create a message'}
								</Typography>
								<Button
									autoFocus
									color="inherit"
									onClick={handleSubmit}
									className={classes.submitButton}
								>
									{this.state.buttonType === 'Edit' ? 'Save' : 'Submit'}
								</Button>
							</Toolbar>
						</AppBar>

						<form className={classes.form} noValidate>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="todoTitle"
										label="Todo Title"
										name="title"
										autoComplete="todoTitle"
										helperText={errors.title}
										value={this.state.title}
										error={errors.title ? true : false}
										onChange={this.handleChange}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="todoDetails"
										label="Todo Details"
										name="body"
										autoComplete="todoDetails"
										multiline
										minRows={25}
										maxRows={25}
										helperText={errors.body}
										error={errors.body ? true : false}
										onChange={this.handleChange}
										value={this.state.body}
									/>
								</Grid>
							</Grid>
						</form>
					</Dialog>

{/* Normal todo cards view */}
					<Grid container spacing={2}>
						{this.state.todos.map( (todo) => 
              todo.title.includes(this.state.filter) ? (
							<Grid item xs={12} sm={6}>
								<Card className={classes.root} variant="outlined">
									<CardContent>
										<Typography variant="h6" component="h2">
											{todo.title}
										</Typography>
										<Typography className={classes.pos} color="textSecondary">
											{dayjs(todo.createdAt).fromNow()}
										</Typography>
										<Typography variant="h7" component="h4">
											{'by ' + todo.user}
										</Typography>
										<Typography variant="body2" component="p">
											{`${todo.body.substring(0, 25)}`}
										</Typography>
									</CardContent>
									<CardActions>
										<Button size="small" color="primary" onClick={() => this.handleViewOpen({ todo })}>
											{' '}
											View{' '}
										</Button>
										<Button size="small" color="primary" onClick={() => this.handleEditClickOpen({ todo })}>
											Edit
										</Button>
										<Button size="small" color="primary" onClick={() => this.deleteTodoHandler({ todo })}>
											Delete
										</Button>
									</CardActions>
								</Card>
							</Grid>
						) :null )}
					</Grid>

{/* Dialog-Modal View single Todo: */}
					<Dialog
						onClose={handleViewClose}
						aria-labelledby="customized-dialog-title"
						open={viewOpen}
						fullWidth
						classes={{ paperFullWidth: classes.dialogeStyle }}
					>
						<DialogTitle id="customized-dialog-title" onClose={handleViewClose}>
							{this.state.title}
						</DialogTitle>
						<DialogContent dividers>
							<TextField
								fullWidth
								id="todoDetails"
								name="body"
								multiline
								readonly
								minRows={1}
								maxRows={25}
								value={this.state.body}
								InputProps={{
									disableUnderline: true
								}}
							/>
						</DialogContent>
					</Dialog>
				</main>
            );
		}
	}
}

export default withStyles(styles)(todo);