import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import home from "./pages/home";
import login from "./pages/login";
import signup from "./pages/signup";
import "./App.css";

const theme = createTheme({
//Wound Solutions:
  palette: {
   primary: {
//    main: '#640D03', // brown
    main: '#4e3853', // purple
    contrastText: "#fff",
   },
//ConsultUs:
    // primary: {
    //   light: "#33c9dc",
    //   main: "#eb3486",
    //   dark: "#d50000",
    //   contrastText: "#fff",
    // },
    secondary: {
      main: "#a1320c", // dark red
      // light: "#33c9dc",
      // main: "#eb3486",
      // dark: "#d50000",
      contrastText: "#fff",
    },
    action: {
      // main: "#D81710", 
      main: "#cb5921",
      // light: "#33c9dc",
      // main: "#eb3486",
      // dark: "#d50000",
      contrastText: "#fff"
    },
    info: {
      main: "#e6e1d7",
    },

  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Switch>
            <Route exact path="/login" component={login} />
            <Route exact path="/signup" component={signup} />
            <Route exact path="/" component={home} />
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
