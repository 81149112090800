import React, { useEffect, useState, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { CSVLink } from "react-csv";
import { refreshToken } from './refresh.js'
import axios from 'axios';
//import { makeStyles } from "@material-ui/core/styles"
import { withStyles } from '@mui/styles';

import { Button,
         CircularProgress,
         Grid, 
         MenuItem,
         Paper,
         Table,
         TableBody,
         TableCell,
         TableContainer,
         TableHead,
         TableRow,
         TextField
} from '@mui/material';


const styles = theme => ({
  button: {
    color: '#606060',
    '&:hover': {
      backgroundColor: '#CEB8E0' 
    },
  },
  link: {
    color: '#606060',
    textdecoration: 'none'
  },
  reportGrid: {
    color: '#DDDDDD',
    // ConsultUs
    // background: 'linear-gradient(45deg, #FF8E53D0 30%, #FE6B8BD0 90%)',
    // Wound Solutions:
    background: 'linear-gradient(45deg, #cb5921D0 30%, #a99787D0 90%)',
  },
  root: {
    color: '#202020',
    '&:hover': {
      backgroundColor: '#CEB8E0' 
    },
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 205, 235, .3)',
    height: 38,
    padding: '0 30px',
  }
});


function Report (props) {

  const GDATA = [
    {
      SAID: "5501125255085",
      admin: 0, 
      date: "2021-11-18", 
      firstName: "MTHANDENI", 
      home: "1", 
      id: "LL3rPtoRZPrOuElYqvDg",
      medicalAid: "HOSMED",
      memberNo: "90737261",
      notes: "xxx",
      patientName: "ZUNGU",
      seenBy: "vasta",
      vac: "1"
    }
  ];

  const YEAR = new Date().toJSON().slice(0, 4)
  const MONTH = new Date().toJSON().slice(5, 7)
  const FILENAME = "report_" + YEAR + '_' + MONTH + ".csv"
  
  const [month, setMonth] = useState(MONTH)
  const [year, setYear] = useState(YEAR)
  const [fileName, setFilename] = useState(FILENAME)
  const [visits, setVisits] = useState([GDATA])
  const [circular, setCircular] = useState(false)
  const [login, setLogin] = useState(false)

  const getVisits = useCallback( async () => {
    setCircular(true)
    const authToken = localStorage.getItem('AuthToken');
    axios.defaults.headers.common = { Authorization: `${authToken}` };
	try {
	  const { data } = await axios.get(`/api/visits/gdate/${props.groupId}/${year}/${month}/`);
	  setVisits(data)
	  setCircular(false)
	} catch (error) {
		if (error.response.status === 403) {
		  setLogin(true)
		}
		else {        
		  setCircular(false)
		  console.log(error)
		}
	  }
  } , [year, month, props.groupId]);

  
  useEffect(() => {
    const r = refreshToken()
    if (r === 403) {
      setLogin(true)
    }
    else {
      getVisits()
      setFilename("report_" + year + '_' + month + ".csv")
    }
  }, [fileName, getVisits, month, year]);

  function homeMap (home) {
    return ( 
      home === '1' ? 'Home' :
      home === '2' ? 'Clinic' :
      home === '3' ? 'Hospital' : 
      home === '4' ? 'Other' : 
       'unknown'
    )  
  }

  function vacMap (vac) {
    return ( 
      vac === '1' ? 'conv' :
      vac === '7' ? 'Vertice' : 
      vac === '101' ? 'Genadyne' : 
      vac === '105' ? 'KCI' : 
      vac === '104' ? 'Smith&Nephew' : 
      'unknown'
    )  
  }

  const camelCase = str => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const filterColumns = data => {
      
      if (data[0] === undefined) {
        data = GDATA
      }
      const columns = Object.keys(data[0]);
      let headers = [];
      columns.forEach((col, idx) => {
        if (col !== "name") {
        // if (idx !== 0) {
          headers.push({ label: camelCase(col), key: col });
        }
      })
  }

  function replaceCodes (visits) {
    const data = []
    for (const v of visits) {
      let w = {...v}
      w.vac = vacMap(v.vac)
      w.home = homeMap(v.home)
      data.push(w)
    }
    return data
  }

  const { classes } = props
  const thinking = (circular === true) ? (
    <CircularProgress size={40} />
  ) : ''

  const redirect = (login === true) ? (
    <Redirect to={{ pathname: "/login" }} />
  ) : ""

  return (
    <div>
      { redirect }
      <h1>Klimbim</h1>
      <form>
        <Grid container item xs={12} spacing={2} >
          {/* <Grid item xs={1}></Grid> */}
          <Grid item xs={1}>
            <TextField
              variant="standard"
              id="MONTH"
              label="Month"
              name="month"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              select
            >
              <MenuItem value="01">JAN</MenuItem>
              <MenuItem value="02">FEB</MenuItem>
              <MenuItem value="03">MAR</MenuItem>
              <MenuItem value="04">APR</MenuItem>
              <MenuItem value="05">MAY</MenuItem>
              <MenuItem value="06">JUN</MenuItem>
              <MenuItem value="07">JUL</MenuItem>
              <MenuItem value="08">AUG</MenuItem>
              <MenuItem value="09">SEP</MenuItem>
              <MenuItem value="10">OCT</MenuItem>
              <MenuItem value="11">NOV</MenuItem>
              <MenuItem value="12">DEC</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={1}>
            <TextField
              variant="standard"
              id="YEAR"
              label="Year"
              name="year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              select
            >
              <MenuItem value="2021">2021</MenuItem>
              <MenuItem value="2022">2022</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3}>
            {thinking}
          </Grid>
          <Grid item xs={4}>
            <Button 
              variant="outlined"
              className={classes.root}
            >
              <CSVLink 
                className={classes.link}

                data={replaceCodes(visits)} 
                headers={filterColumns(visits)} 
                filename={fileName}
              >
                Download as CSV
              </CSVLink> 
            </Button>
          </Grid>
        </Grid>
      </form>
      <TableContainer component={Paper} className={classes.reportGrid}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="right">date</TableCell>
              <TableCell align="right">surname</TableCell>
              <TableCell align="right">name</TableCell>
              <TableCell align="right">SAID</TableCell>
              <TableCell align="right">medicalAid</TableCell>
              <TableCell align="right">memberNo</TableCell>
              <TableCell align="right">home</TableCell>
              <TableCell align="right">vac</TableCell>
              <TableCell align="right">seenBy</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visits.map((visit) => (
              <TableRow
                key={visit.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="right">{visit.date}</TableCell>
                <TableCell align="right">{visit.patientName}</TableCell>
                <TableCell align="right">{visit.firstName}</TableCell>
                <TableCell align="right">{visit.SAID}</TableCell>
                <TableCell align="right">{visit.medicalAid}</TableCell>
                <TableCell align="right">{visit.memberNo}</TableCell>
                <TableCell align="right">{homeMap(visit.home)}</TableCell>
                <TableCell align="right">{vacMap(visit.vac)}</TableCell>
                {/* <TableCell align="right">{visit.home}</TableCell>
                <TableCell align="right">{visit.vac}</TableCell> */}
                <TableCell align="right">{visit.seenBy}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default withStyles(styles)(Report);



