import React from 'react';
// import makeStyles from '@material-ui/styles/makeStyles';
import { withStyles } from '@mui/styles';
import {
  Paper,
  Grid
 } from '@mui/material';

const useStyles = withStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  marginTop: {
    marginTop: '100pt'
  },
  marginLeft: {
    marginLeft: '100pt'
  }
}));

export default function Layout() {
  const classes = useStyles();

  function FormRow() {
    return (
      <React.Fragment>
        <Grid item xs={4} name='One'>Wound
          <Paper className={classes.paper}>item</Paper>
        </Grid>
        <Grid item xs={4} name='Two'>Care
          <Paper className={classes.paper}>item</Paper>
        </Grid>
        <Grid item xs={4} name='Three'>Unit
          <Paper className={classes.paper}>item</Paper>
        </Grid>
      </React.Fragment>
    );
  }

  return (

    <div className={classes.root}>
      <div className={classes.marginTop}></div>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
          <FormRow />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <FormRow />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <FormRow />
        </Grid>
      </Grid>

      <hr />
    </div>
  );
}
